/** Angluar Modules */
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/** 3rd Party Modules */
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { MaterialModule } from './../material/material.module';

import {
  DeleteModalComponent,
  UpdateModalComponent,
  UploadModalComponent,
  ImageViewComponent,
  ImagePreviewComponent,
  ImageSelectComponent,
  ImageEditComponent,
  CardUnderCopyrightComponent,
  PageHeaderComponent,
  PaintImageDialogComponent
} from './components';

import { JstPipe } from './pipes/jst.pipe';
import { JstFullPipe } from './pipes/jst-full.pipe';
import { HashtagPipe } from './pipes/hashtag.pipe';
import { MentionModule } from 'angular-mentions';

export const COMPONENTS = [
  DeleteModalComponent,
  UploadModalComponent,
  UpdateModalComponent,
  ImageViewComponent,
  ImagePreviewComponent,
  ImageSelectComponent,
  ImageEditComponent,
  CardUnderCopyrightComponent,
  PageHeaderComponent,
  PaintImageDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollDirective,
        FlexLayoutModule,
        TruncateModule,
        MaterialModule,
        RouterModule,
        FileUploadModule,
        LazyLoadImageModule,
        MentionModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollDirective,
        FlexLayoutModule,
        TruncateModule,
        DeleteModalComponent,
        UpdateModalComponent,
        UploadModalComponent,
        ImageViewComponent,
        ImagePreviewComponent,
        ImageSelectComponent,
        ImageEditComponent,
        CardUnderCopyrightComponent,
        PageHeaderComponent,
        JstPipe,
        JstFullPipe,
        HashtagPipe
    ],
    declarations: [...COMPONENTS, JstPipe, JstFullPipe, HashtagPipe]
})
export class SharedModule {}
