import { Routes } from '@angular/router';
import { AuthGuard, AuthedGuard } from './core/guards';
import { SecureLayoutComponent, AuthLayoutComponent } from './core/layouts';
import { NotFoundComponent } from './core/containers/not-found/not-found.component';
import { AboutComponent } from './core/containers/about/about.component';
import { LogoutComponent } from './core/containers/logout/logout.component';
import { environment } from './../environments/environment';

export const AppRoutes: Routes = [
  {
    path: '',
    component: SecureLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule),
      },
      {
        path: 'album',
        loadChildren: () => import('./album/album.module').then(m => m.AlbumModule),
      },
      {
        path: 'upload',
        loadChildren: () => import('./upload/upload.module').then(m => m.UploadModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: 'userinfo',
        loadChildren: () => import('./userinfo/userinfo.module').then(m => m.UserinfoModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'master',
        loadChildren: () => import('./master/master.module').then(m => m.MasterModule),
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [AuthedGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { title: `${environment.appName} -${environment.appName}について` },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: `${environment.appName} -404 Not Page Found` },
  },
];
