import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { take ,  tap ,  catchError } from 'rxjs/operators';

import { UserLoginService, UserParametersService } from './../../services';

/**
 * 管理者領域へのアクセスを制御
 *
 * @export
 * @class AdminGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AdminGuard  {
  constructor(
    private userService: UserLoginService,
    private userParameterService: UserParametersService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userParameterService.isAdmin().pipe(
      take(1),
      tap(isAdmin => {
        if (!isAdmin) {
          this.router.navigate(['/']);
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }
}
