import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { take ,  tap ,  map ,  catchError } from 'rxjs/operators';

import { UserLoginService } from './../../services';

/**
 * ログイン済みかどうかを判定してログインしていれば/にリダイレクト
 *
 * @export
 * @class AuthedGuard
 * @implements {CanActivate}
 * @implements {CanActivateChild}
 */
@Injectable()
export class AuthedGuard  {
  constructor(private userService: UserLoginService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthed => {
        if (isAuthed) {
          this.router.navigate(['/']);
        }
      }),
      map(isAuthed => (isAuthed === false ? !isAuthed : isAuthed)),
      catchError(error => {
        return of(false);
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthed => {
        if (isAuthed) {
          this.router.navigate(['/']);
        }
      }),
      map(isAuthed => (isAuthed === false ? !isAuthed : isAuthed)),
      catchError(error => {
        return of(false);
      })
    );
  }
}
