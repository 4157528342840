import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PostList, Post } from './../models/post.model';
import * as fromRoot from './../reducers';
import * as search from './../posts/actions/posts.actions';
import * as loading from './../core/actions/loading.actions';
import { environment } from '../../environments/environment';

import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';

/**
 * 投稿データのリクエストを行う
 *
 * @export
 * @class SearchService
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  /**
   * Creates an instance of SearchService.
   * @param {LoadingService} loadingService
   * @param {ApiService} apiService
   * @memberof SearchService
   */
  constructor(
    private store: Store<fromRoot.State>,
    private apiService: ApiService
  ) {}

  /**
   * post_idから画像を取得
   *
   * @param {string} post_id
   * @returns {(Observable<Post>)}
   * @memberof SearchService
   */
  public searchOne(post_id: string): Observable<Post> {
    this.store.dispatch(new loading.StartAnimating());

    // リクエストを送る
    return this.apiService.get<Post>(`/posts/${post_id}`).pipe(
      map(result => {
        if (result) {
          this.store.dispatch(new loading.StopAnimating());
          return result;
        } else {
          this.store.dispatch(new loading.StopAnimating());
          return null;
        }
      })
    );
  }

  /**
   * パラメータが有効かを検証する
   *
   * @private
   * @param {string} key
   * @param {any} params
   * @returns
   * @memberof SearchService
   */
  private checkIfPropertyIsValid(key: string, params: any) {
    if (params === null) {
      return false;
    }

    return params.hasOwnProperty(key) && params[key] !== '';
  }

  /**
   * 検索用
   *
   * @param {Params} params
   * @returns {Observable<Post[]>}
   * @memberof SearchService
   */
  public search(params: Params): Observable<Post[]> {
    this.store.dispatch(new loading.StartAnimating());

    let param: HttpParams = new HttpParams().append(
      'size',
      String(environment.fetchSize)
    );

    // オフセット
    if (this.checkIfPropertyIsValid('from', params)) {
      param = param.append('from', params.from);
    }
    // 期間指定
    if (this.checkIfPropertyIsValid('date_from', params)) {
      param = param.append('date_from', params.date_from);
    }
    if (this.checkIfPropertyIsValid('date_to', params)) {
      param = param.append('date_to', params.date_to);
    }
    // イベント、部門、店舗
    if (this.checkIfPropertyIsValid('event_id', params)) {
      param = param.append('event_id', params.event_id);
    }
    if (this.checkIfPropertyIsValid('category_id', params)) {
      param = param.append('category_id', params.category_id);
    }
    if (this.checkIfPropertyIsValid('shop_id', params)) {
      param = param.append('shop_id', params.shop_id);
    }
    if (this.checkIfPropertyIsValid('shops', params)) {
      param = param.append('shops', params.shops);
    }
    // フリーワード検索
    if (this.checkIfPropertyIsValid('word', params)) {
      param = param.append('word', params.word);
    }
    // リクエストを送る
    return this.apiService.get<PostList>('/posts', param).pipe(
      map(result => {
        this.store.dispatch(new search.SearchCount(result.total));
        this.store.dispatch(new loading.StopAnimating());
        const posts = result.posts;
        return posts;
      })
    );
  }
}
