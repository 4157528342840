import { Component } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-card-under-copyright',
  templateUrl: './card-under-copyright.component.html',
  styleUrls: ['./card-under-copyright.component.scss'],
})
export class CardUnderCopyrightComponent {
  public copyrightYear: string;

  constructor() {
    this.copyrightYear = '2018';
  }
}
