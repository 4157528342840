<div class="search-bar" (keyup.enter)="onSearch()" fxFlex>
  <form [formGroup]="searchForm" class="search-form" action="/search">
    <button mat-icon-button class="search-button">
      <mat-icon class="search-icont">search</mat-icon>
    </button>
    <div class="search-input-wrapper" *ngIf="!_isAlbum(); else albumSearchBar">
      <input type="text" placeholder='検索' (focus)="onFocus()" (blur)="onBlur()" formControlName="word" [readonly]="openDetailSearch" [matAutocomplete]="recentSearch" />
    </div>
    <ng-template #albumSearchBar>
      <div class="search-input-wrapper">
        <input type="text" placeholder='アルバム検索(名前、説明、店舗名)' formControlName="word" />
      </div>
    </ng-template>
    <button mat-icon-button class="search-open-button" fxHide.lt-sm *ngIf="!_isAlbum()" (click)="onUpdownBtnClick()">
      <mat-icon *ngIf="!openDetailSearch; else detailSearchBtn">keyboard_arrow_down</mat-icon>
      <ng-template #detailSearchBtn>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </ng-template>
    </button>
    <button mat-icon-button class="updown-button" fxHide.gt-xs>
      <mat-icon *ngIf="showResetButton(searchForm.value.word)" (click)="onClickResetForm()">cancel</mat-icon>
    </button>
    <button mat-icon-button class="search-open-button" fxHide.gt-xs (click)="toggleSearchForm()">
      <mat-icon *ngIf="!isMobileSearchFormOpen; else isMobileSearchFormOpenBtn" class="mat-option-text__cancelCloseWindow">keyboard_arrow_down</mat-icon>
      <ng-template #isMobileSearchFormOpenBtn>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </ng-template>
    </button>
  </form>

  <!-- SP時に表示させる詳細検索ボックス -->
  <app-search-form
    (clickOutside)="closeSearchWindow($event)"
    @panelOpenTrigger
    *ngIf="isSp && isMobileSearchFormOpen"
    fxHide.gt-xs
  >
  </app-search-form>
</div>
<mat-autocomplete #recentSearch="matAutocomplete">
  <mat-option fxLayout='row' *ngFor="let word of filteredWords | async" [value]="word">
    <span class="mat-option-text__cancelCloseWindow" fxFlex="95%">{{ word }}</span>
    <span class="close-button mat-option-text__cancelCloseWindow" fxFlex="5%" (click)="onClickDeleteItem(word)">
      <a mat-icon-button><mat-icon>close</mat-icon></a>
    </span>
  </mat-option>
</mat-autocomplete>
