import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';

import * as fromRoot from './../../../reducers';
// import * as search from './../../../posts/actions/posts.actions';
import * as navigation from './../../actions/navigation.actions';
import { SearchHistoryService } from './../../../services';
import { environment } from './../../../../environments/environment';
import { Router } from '@angular/router';
import { panelOpenTrigger } from '../../../shared/animations/animations';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [panelOpenTrigger],
})
export class SearchComponent implements OnInit, OnDestroy {
  public placeholder = '検索';
  public filteredWords: Observable<string[]>;
  public resentSearchWords: string[];
  public isSp: boolean;
  @Input()
  public openDetailSearch: boolean;
  @Output()
  public close = new EventEmitter<boolean>();
  @Output()
  public changeSearchFormState = new EventEmitter();
  public isMobileSearchFormOpen: boolean;

  // subscriptions
  private subscription: Subscription;
  searchForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private store: Store<fromRoot.State>
  ) {
    this.subscription = new Subscription();
    this.searchForm = this.fb.group({
      word: new UntypedFormControl('', []),
    });
    this.filteredWords = this.searchForm.valueChanges.pipe(
      startWith(null),
      map(val => (val ? this.filter(val.word) : this.resentSearchWords.slice()))
    );
    this.isSp = /iP(hone|(o|a)d)|Android/.test(navigator.userAgent) || false;
    this.isMobileSearchFormOpen = false;
  }


  ngOnInit() {
    this.fetchRecentSearchWord();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSearch() {
    const word = (this.searchForm.value.word || '').replace(/^\s+|\s+$/g, '');
    if (word !== '') {
      this.searchHistoryService.pushItem(word);
      this.fetchRecentSearchWord();
    }
    this.close.emit(true);

    const queryParams: object = { word: word };
    sessionStorage.setItem(
      environment.local_searchQueryParam,
      JSON.stringify(queryParams)
    );

    if (!this._isAlbum()) {
      this.store.dispatch(
        new navigation.Go({
          path: ['/search'],
          query: queryParams,
        })
      );
    } else {
      this.store.dispatch(
        new navigation.Go({
          path: ['/album'],
          query: queryParams,
        })
      );
    }
  }

  onUpdownBtnClick() {
    this.searchForm.reset();
    this.changeSearchFormState.emit();
  }

  onClickResetForm() {
    this.searchForm.setValue({ word: '' });
  }

  onClickDeleteItem(word: string) {
    this.searchHistoryService.clearItemsValue(word);
    this.fetchRecentSearchWord();
    this.searchForm.reset();
  }

  onFocus() {
    this.placeholder = '';
  }

  onBlur() {
    this.placeholder = '検索';
  }

  showResetButton(word: string) {
    return word !== '' ? true : false;
  }

  private filter(val: string): string[] {
    if (!val) {
      return this.resentSearchWords;
    }
    return this.resentSearchWords.filter(
      word => word.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }

  private fetchRecentSearchWord() {
    this.resentSearchWords = this.searchHistoryService
      .getItems()
      .map(x => x.word);
  }

  _isAlbum(): boolean {
    const url = this.router.url.split('?')[0];
    return url.match('/album') ? true : false;
  }

  closeSearchWindow() {
    this.close.emit(true);
  }

  toggleSearchForm() {
    this.isMobileSearchFormOpen = !this.isMobileSearchFormOpen;
  }
}
