import { Action } from '@ngrx/store';

import { Filter } from '../../models';

export enum SearchActionTypes {
  SetSearchFilter = '[Search] Set Search Filter',
  DeleteSearchFilter = '[Search] Delete Search Filter',
}

export class SetSearchFilter implements Action {
  readonly type = SearchActionTypes.SetSearchFilter;

  constructor(public payload: Filter) {}
}

export class DeleteSearchFilter implements Action {
  readonly type = SearchActionTypes.DeleteSearchFilter;
}

export type SearchActions = SetSearchFilter | DeleteSearchFilter;
