import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { UpdateModalComponent } from './update-modal/update-modal.component';
import { UploadModalComponent } from './upload-modal/upload-modal.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ImageSelectComponent } from './image-select/image-select.component';
import { ImageEditComponent } from './image-edit/image-edit.component';
import { CardUnderCopyrightComponent } from './card-under-copyright/card-under-copyright.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PaintImageDialogComponent } from './paint-image-dialog/paint-image-dialog.component';

export {
  DeleteModalComponent,
  UpdateModalComponent,
  UploadModalComponent,
  ImageViewComponent,
  ImagePreviewComponent,
  ImageSelectComponent,
  ImageEditComponent,
  CardUnderCopyrightComponent,
  PageHeaderComponent,
  PaintImageDialogComponent
};
