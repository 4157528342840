import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';

export enum UrlTypes {
  Users = 1,
  Other = 10
}

@Injectable({
  providedIn: 'root'
})
export class TenantUtil {
  private tenant_id: string;
  private env: string;
  private bucketDomain: string;

  constructor() {
    // localhostだったら、'dev'を返す
    // それ以外は、tenant_idを返す
    // e.g. stg.post-for.com → stg
    this.tenant_id =
      location.hostname === 'localhost'
        ? 'dev'
        : location.hostname.split('.')[0];

    // domainの設定
    if (environment.env === 'dev' || environment.env === 'stg') {
      this.env = environment.env + '.';
      this.bucketDomain = environment.env;
    } else {
      this.env = environment.env;
      this.bucketDomain = 'prod';
    }
  }

  get tenantId(): string {
    return this.tenant_id;
  }

  get apiEndpoint(): string {
    return `https://api.${this.env}postfor.jp/v0`;
  }

  get mediaEndpoint(): string {
    return `https://img.${this.env}postfor.jp/${this.tenant_id}/`;
  }

  get uploadBucket(): string {
    return `media-original-${this.bucketDomain}/${this.tenant_id}`;
  }

  get tenantIdEndpoint(): string {
    return `https://api.${this.env}postfor.jp/v0/tenants/${this.tenant_id}`;
  }
}
