import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Base64 } from 'js-base64';
import * as queryString from 'querystring';

import { ApiService } from './../services/api.service';
import { environment } from '../../environments/environment';
import { UrlTypes } from './../shared/util/tenant-util';

/**
 * Pre signed urlの取得と更新
 *
 * @export
 * @class ImageUrlService
 */
@Injectable({
  providedIn: 'root'
})
export class ImageUrlService {
  private signedUrl: string;

  /**
   * Creates an instance of ImageUrlService.
   * @param {ApiService} apiService
   * @memberof ImageUrlService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Pre signed urlを取得
   *
   * @returns {Observable<string>}
   * @memberof ImageUrlService
   */
  public getSignedUrl(): Observable<string> {
    // キャッシュがあればキャッシュを使う
    if (this.signedUrl && !this.isExpired(this.signedUrl)) {
      return of(this.signedUrl);
    }
    // キャッシュが無い or 期限切れの場合は再取得
    return this.fetchSignedUrl();
  }

  /**
   * 署名の有効期限を検証する
   *
   * @public
   * @param {string} signedUrl
   * @returns {boolean}
   * @memberof ImageUrlService
   */
  public isExpired(signedUrl: string): boolean {
    const urlParams = new URLSearchParams(signedUrl);
    const policy = urlParams.get('Policy');

    // base64 → JSON文字列に
    // decodeの際にJSON文字列の末尾に不要な文字が入ることがある
    let encodedChar = Base64.decode(policy).replace(/[\u0000-\u0019]+/g, '');

    // JSON文字列の末尾がJSONの閉じ括弧("}")でなければ、その文字を削除する
    const lastChar = encodedChar.substr(-1, 1);
    if (lastChar !== '}') {
      encodedChar = encodedChar.slice(0, -1);
    }

    const decodedParams = JSON.parse(encodedChar);
    const expireTimeStr =
      decodedParams.Statement[0].Condition.DateLessThan['AWS:EpochTime'];

    // 期限をDateオブジェクトに変換
    const expireTime = new Date(expireTimeStr * 1000);
    // 更新するかのしきい値は5分以内
    const limitTime = new Date(
      new Date().setMinutes(new Date().getMinutes() + 5)
    );
    // 期限の検証
    if (expireTime >= limitTime) {
      // 有効
      return false;
    } else {
      // 無効
      return true;
    }
  }

  /**
   * Pre Signed urlをAPIから取得してキャッシュする
   *
   * @public
   * @returns {Observable<string>}
   * @memberof ImageUrlService
   */
  public fetchSignedUrl(): Observable<string> {
    // TODO 暫定でURLを変える
    return this.apiService
      .get<any>('/presignedUrl', null, false, UrlTypes.Users)
      .pipe(
        map(result => {
          this.signedUrl = this.fullUrlToQueryString(result.signedUrl);
          return this.signedUrl;
        })
      );
  }

  //////// Private //////////
  /**
   * URLから署名部分(?以降)のみを抽出
   *
   * @private
   * @param {string} url
   * @returns {string}
   * @memberof ImageUrlService
   */
  private fullUrlToQueryString(url: string): string {
    // query string → Object
    const urlObj = new URL(url);
    return urlObj.search.slice(1);
  }
}
