import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as AWS from 'aws-sdk/global';
import { CognitoIdentityCredentials } from 'aws-sdk/lib/credentials/cognito_identity_credentials';
import { Store, select } from '@ngrx/store';

import { CognitoUtil } from './cognito.service';
import * as fromRoot from './../reducers';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsUtil {
  jwt$: Observable<string>;

  constructor(
    private cognitoUtil: CognitoUtil,
    private store: Store<fromRoot.State>
  ) {
    this.jwt$ = this.store.pipe(select(fromRoot.getJwt));
  }

  /**
   * Storeからjwtを取得してグローバルオブジェクトを初期化
   *
   * @returns {Observable<boolean>}
   * @memberof UserlistService
   */
  public initAws(): Observable<boolean> {
    return this.jwt$.pipe(
      switchMap((jwt: string) => this.cognitoUtil.buildCognitoCreds(jwt)),
      tap(creds => {
        AWS.config.update({
          credentials: creds,
          region: environment.region
        });
      }),
      map(() => true)
    );
  }
}
