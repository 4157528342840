import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  // サイドナビゲーション(表示/非表示)
  OpenSidenav = '[Layout] Open Sidenav',
  CloseSidenav = '[Layout] Close Sidenav',
  // サイドナビゲーション(表示モードの切り替え)
  OverSidenav = '[Layout] Over Sidenav',
  SideSidenav = '[Layout] Side Sidenav',
  // 詳細検索フォーム(表示/非表示)
  OpenSearchForm = '[Layout] Open SearchForm',
  CloseSearchForm = '[Layout] Close SearchForm',
}

export class OpenSidenav implements Action {
  readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = LayoutActionTypes.CloseSidenav;
}

export class OverSidenav implements Action {
  readonly type = LayoutActionTypes.OverSidenav;
}

export class SideSidenav implements Action {
  readonly type = LayoutActionTypes.SideSidenav;
}

export class OpenSearchForm implements Action {
  readonly type = LayoutActionTypes.OpenSearchForm;
}

export class CloseSearchForm implements Action {
  readonly type = LayoutActionTypes.CloseSearchForm;
}

export type LayoutActions =
  | OpenSidenav
  | CloseSidenav
  | OverSidenav
  | SideSidenav
  | OpenSearchForm
  | CloseSearchForm;
