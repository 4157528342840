import { Action } from '@ngrx/store';

export enum NotificationCountActionTypes {
  // 件数取得
  LoadNotificationCount = '[NotificationCount] Load Notification Count',
  LoadNotificationCountComplete = '[NotificationCount] Load Notification Count Complete',
  LoadNotificationCountError = '[NotificationCount] Load Notification Count Error',
  // 既読
  MarkReadNotifications = '[NotificationCount] Mark Read Notifications',
  MarkReadNotificationsComplete = '[NotificationCount] Mark Read Notifications Complete',
  MarkReadNotificationsError = '[NotificationCount] Mark Read Notifications Error',
  //OtherError
  MarkReadOtherError = '[NotificationCount] Other Error',
}

export class LoadNotificationCount implements Action {
  readonly type = NotificationCountActionTypes.LoadNotificationCount;
}

export class LoadNotificationCountComplete implements Action {
  readonly type = NotificationCountActionTypes.LoadNotificationCountComplete;

  constructor(public payload: number) {}
}

export class LoadNotificationCountError implements Action {
  readonly type = NotificationCountActionTypes.LoadNotificationCountError;

  constructor(public payload: string) {}
}

export class MarkReadNotifications implements Action {
  readonly type = NotificationCountActionTypes.MarkReadNotifications;
}

export class MarkReadNotificationsComplete implements Action {
  readonly type = NotificationCountActionTypes.MarkReadNotificationsComplete;

  constructor(public payload: boolean) {}
}

export class MarkReadNotificationsError implements Action {
  readonly type = NotificationCountActionTypes.MarkReadNotificationsError;

  constructor(public payload: string) {}
}

export class MarkReadOtherError implements Action {
  readonly type = NotificationCountActionTypes.MarkReadOtherError;

  constructor(public payload: string) {}
}

export type NotificationCountActions =
  | LoadNotificationCount
  | LoadNotificationCountComplete
  | LoadNotificationCountError
  | MarkReadNotifications
  | MarkReadNotificationsComplete
  | MarkReadNotificationsError;
