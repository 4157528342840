import { Action } from '@ngrx/store';

import {
  NotificationCountActions,
  NotificationCountActionTypes,
} from '../actions/notification-count.actions';

export interface State {
  count: number;
}

export const initialState: State = {
  count: 0,
};

export function reducer(
  state = initialState,
  action: NotificationCountActions
): State {
  switch (action.type) {
    case NotificationCountActionTypes.LoadNotificationCountComplete: {
      return {
        count: action.payload,
      };
    }

    case NotificationCountActionTypes.MarkReadNotificationsComplete: {
      return {
        count: action.payload === true ? 0 : state.count,
      };
    }

    default:
      return state;
  }
}

export const getNotificationCount = (state: State) => state.count;
