import { throwError as observableThrowError, Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';
import * as mime from 'mime-types';

import { S3Service } from './s3.service';
import { Media } from './../models';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  /**
   * Creates an instance of MediaService.
   * @param {ApiService} apiService
   * @memberof MediaService
   */
  constructor(private s3Service: S3Service, private snackBar: MatSnackBar) {}

  /**
   * 画像アップロード(複数対応)
   *
   * @param {FormData[]} imgData
   * @returns {Observable<Media[]>}
   * @memberof MediaService
   */
  public multipleUpload(imgData: File[]): Observable<Media[]> {
    const requests: Observable<Media>[] = [];

    for (const img of imgData) {
      requests.push(this.upload(img));
    }
    return forkJoin(requests);
  }

  /**
   * MediaId(UUID)の取得
   * S3オブジェクトのKeyになる
   *
   * @private
   * @returns {string}
   * @memberof MediaService
   */
  private getMediaId(): string {
    return uuidv4();
  }

  /**
   * MimeTypeから拡張子を取得
   *
   * @private
   * @param {string} mimetype
   * @returns {string}
   * @memberof MediaService
   */
  private getFileExtension(mimetype: string): string {
    const ext = mime.extension(mimetype);
    return ext ? ext : '';
  }

  /**
   * 画像アップロード
   *
   * @private
   * @param {FormData} imgData
   * @returns {Observable<Media>}
   * @memberof MediaService
   */
  public upload(imgData: File): Observable<Media> {
    const mediaId = this.getMediaId();
    const imageExt = this.getFileExtension(imgData.type);

    return this.s3Service.uploadData(imgData, mediaId).pipe(
      map(data => {
        const sendMedia: Media = {
          id: data.Key.split('/').pop(),
          ext: imageExt
        };
        return sendMedia;
      }),
      catchError(error => this.handleHttpError(error))
    );
  }

  /**
   * APIのエラーハンドリング
   * 結果はSnackBarで表示する
   * パターン: APIのタイムアウト, その他
   * TODO: S3 SDK向けのエラーハンドリング
   *
   * @private
   * @param {HttpErrorResponse} error
   * @returns
   * @memberof MediaService
   */
  private handleHttpError(error: HttpErrorResponse) {
    // open SnackBar
    if (error.message === 'Timeout has occurred') {
      this.snackBar.open(
        '通信が不安定のため画像アップロードに失敗しました。しばらく経ってから再度実行してください。',
        '',
        { duration: 3000 }
      );
    } else {
      this.snackBar.open(
        `エラーが発生しました。しばらく経ってから再度実行してください。(${
          error.message
        })`,
        '',
        { duration: 3000 }
      );
    }
    // throw error
    return observableThrowError(error.message);
  }
}
