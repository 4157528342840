import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot, Params } from '@angular/router';
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  fragment: string;
}

@Injectable()
export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams, fragment } } = routerState;
    const { params } = route;

    return { url, params, queryParams, fragment };
  }
}
