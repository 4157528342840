<div class="page__wapper" fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="60%" fxFlex.lt-sm="100%">
    <div fxLayout="column">
      <!-- logo -->
      <img class="main-logo" fxHide.lt-sm src="assets/posfor_big_logo.svg" alt="postfor" width="140">
      <img class="main-logo" fxHide.gt-xs src="assets/posfor_big_mobile_logo.svg" alt="postfor" width="140">

      <mat-card class="about-card">
        <mat-card-title class="about-card__title">Postforについて</mat-card-title>
        <mat-card-content class="about-card__caption">
          <p>Postforは、売り場写真共有サービスです。</p>
        </mat-card-content>
        <mat-card-subtitle class="about-card__subtitle">バージョン情報</mat-card-subtitle>
        <mat-card-content>
          <p [matTooltip]="commitApiVersion">API: {{ appApiVersion }} + {{ commitApiVersion | slice: 0: 7 }}</p>
          <p [matTooltip]="commitFrontVersion">サイト: {{ appFrontVersion }} + {{ commitFrontVersion | slice: 0: 7 }}</p>
        </mat-card-content>
        <mat-card-subtitle class="about-card__subtitle">運営会社</mat-card-subtitle>
        <mat-card-content>
          <p>
            <a href="https://www.hands-lab.com/">ハンズラボ株式会社</a>
          </p>
        </mat-card-content>
        <mat-card-footer>
          <div class="about-card__button-row">
            <button mat-raised-button color="primary" (click)="onClickBack()">戻る</button>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
    <!-- コピーライト -->
    <app-card-under-copyright></app-card-under-copyright>
  </div>
</div>
