import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromSearch from './search.reducer';
import * as fromPosts from './posts.reducer';
import * as fromComments from './comments.reducer';
import * as fromRoot from './../../reducers';

import { Post, User, SearchResult } from './../../models';

export interface PostsState {
  search: fromSearch.State;
  posts: fromPosts.State;
  comments: fromComments.State;
}

export interface State extends fromRoot.State {
  posts: PostsState;
}

export const reducers = {
  search: fromSearch.reducer,
  posts: fromPosts.reducer,
  comments: fromComments.reducer,
};

export const getPostsState = createFeatureSelector<PostsState>('posts');

export const getPostsEntitiesState = createSelector(
  getPostsState,
  (state: PostsState) => state.posts
);

export const getSearchState = createSelector(
  getPostsState,
  (state: PostsState) => state.search
);

// 現在選択中のIDを取得
export const getSelectedPostId = createSelector(
  getPostsEntitiesState,
  fromPosts.getSelectedId
);

// Home画面でキャッシュクリアが必要か
export const getIsDirectLink = createSelector(
  getPostsEntitiesState,
  fromPosts.getIsDirectLink
);

export const getSearchPostIds = createSelector(
  getSearchState,
  fromSearch.getIds
);

// 検索結果件数を取得
export const getSearchTotal = createSelector(
  getSearchState,
  fromSearch.getTotal
);

export const getSearchQuery = createSelector(
  getSearchState,
  fromSearch.getQuery
);

export const getSearchLoading = createSelector(
  getSearchState,
  fromSearch.getLoading
);

export const getSearchComplete = createSelector(
  getSearchState,
  fromSearch.getSearchComplete
);

export const getSearchNextComplete = createSelector(
  getSearchState,
  fromSearch.getSearchNextComplete
);

export const getSearchError = createSelector(
  getSearchState,
  fromSearch.getError
);

export const getNonAlbumPostOnly = createSelector(
  getSearchState,
  fromSearch.getNonAlbumPostOnly
);

export const getSearchAllIds = createSelector(
  getSearchState,
  fromSearch.getAllIds
);

export const getDownloadIds = createSelector(
  getSearchState,
  fromSearch.getDownloadIds
);

// getSelectorsでは4つの基本的な状態を取得することが出来る
export const {
  selectIds: getPostIds,
  selectEntities: getPostEntities,
  selectAll: getAllPosts,
  selectTotal: getTotalPosts,
} = fromPosts.adapter.getSelectors(getPostsEntitiesState);

export const getSelectedPost = createSelector(
  getPostEntities,
  getSelectedPostId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

// 絞り込み条件に合うPostを取得
export const getAllAvailablePosts = createSelector(
  getAllPosts,
  getNonAlbumPostOnly,
  (posts, nonAlbumPostOnly) => {
    if (nonAlbumPostOnly) {
      return posts && posts.filter(post => post.album.id === null);
    } else {
      return posts;
    }
  }
);

// 日付サブヘッダーを表示できるかたちに整形
export const getSearchResults = createSelector(
  getAllAvailablePosts,
  posts => {
    const resultPosts: SearchResult[] = [];

    let previous_published_at = '';

    posts.forEach((post: Post) => {
      const date = new Date(post.published_at);
      const published_at = `${date.getFullYear().toString(10)}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

      if (previous_published_at !== published_at) {
        const dailyPosts: SearchResult = {
          date: published_at,
          nodes: [],
        };
        resultPosts.push(dailyPosts);
      }

      resultPosts[resultPosts.length - 1].nodes.push(post);
      previous_published_at = published_at;
    });

    return resultPosts;
  }
);

export const GetPreviousPost = createSelector(
  getPostIds,
  getSelectedPostId,
  (postIds: string[], selectedId: string) => {
    let previousPostId: string;
    postIds.forEach((id, index) => {
      if (selectedId === id) {
        previousPostId = postIds[index - 1];
      }
    });
    // 取得できなかった場合は現在のPostIdを返す
    return previousPostId || selectedId;
  }
);

export const GetNextPost = createSelector(
  getPostIds,
  getSelectedPostId,
  (postIds: string[], selectedId: string) => {
    let nextPostId: string;
    postIds.forEach((id, index) => {
      if (selectedId === id) {
        nextPostId = postIds[index + 1];
      }
    });
    // 取得できなかった場合は現在のPostIdを返す
    return nextPostId || selectedId;
  }
);

// 選択中Postにいいね済みか
export const getIsNiced = createSelector(
  getSelectedPost,
  fromRoot.getUser,
  (post: Post, user: User) => {
    return post &&
      post.nice_users.filter(niceUser => niceUser === user.sub).length > 0
      ? true
      : false;
  }
);

// 選択中Postのいいね数
export const getNiceCount = createSelector(
  getSelectedPost,
  (post: Post) => post && post.nice_count
);

// コメント
export const getCommentsEntitiesState = createSelector(
  getPostsState,
  state => state.comments
);

// 複数画像がアップロードされる/されてた時、どの画像を表示させるか
export const getCurrentImageIndex = createSelector(
  getPostsEntitiesState,
  state => state.currentImageIndex
);

export const {
  selectIds: getCommentIds,
  selectEntities: getCommentEntities,
  selectAll: getAllComments,
  selectTotal: getTotalComments,
} = fromComments.adapter.getSelectors(getCommentsEntitiesState);
