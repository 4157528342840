import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { CognitoUtil } from './cognito.service';

import { RegistrationUser } from './../models/registration-user.model';
import { UrlTypes } from './../shared/util/tenant-util';

@Injectable({
  providedIn: 'root'
})
export class UserManageService {
  /**
   * Creates an instance of PostsService.
   * @param {ApiService} apiService
   * @param {CognitoUtil} cognitoUtil
   * @memberof UserManageService
   */
  constructor(
    private apiService: ApiService,
    public cognitoUtil: CognitoUtil
  ) {}

  /**
   * ユーザー登録
   *
   * @param {RegistUser} body
   * @returns {Observable<any>}
   * @memberof CognitoService
   */
  public registUser(body: RegistrationUser): Observable<any> {
    const path = `/users`;
    return this.apiService.post(path, body, false, UrlTypes.Users);
  }

  /**
   * ユーザー属性の更新
   *
   * @param {string} username
   * @param {any} updateAttrs
   * @param {string} role
   * @param {boolean} logout
   * @returns {Observable<any>}
   * @memberof CognitoUtil
   */
  public updateUser(
    username: string,
    sub: string,
    updateAttrs,
    role: string,
    logout: boolean
  ): Observable<any> {
    return of(null).pipe(
      tap(() => {
        if (logout) {
          this.cognitoUtil.forcedSingOutUser(username);
        }
      }),
      switchMap(() => {
        const user = new RegistrationUser();
        user.name = updateAttrs[0].Value;
        user.email = updateAttrs[1].Value;
        user.role = role;
        user.shop_id = updateAttrs[2].Value;
        const path = `/users/${sub}`;
        return this.apiService.put(path, user, false, UrlTypes.Users);
      })
    );
  }

  /**
   * メール再送信
   *
   * @param {RegistrationUser} body
   * @returns {Observable<any>}
   * @memberof CognitoService
   */
  public resendEmail(body: RegistrationUser): Observable<any> {
    const path = `/users/${body.sub}?resend=true`;
    return this.apiService.put(path, body, false, UrlTypes.Users);
  }

  /**
   * ユーザー取得
   *
   * @param {string} pagenationToken
   * @returns {Observable<any>}
   * @memberof UserManageService
   */
  public listUsers(pagenationToken?: string): Observable<any> {
    let path = `/users`;
    if (pagenationToken) {
      pagenationToken = encodeURIComponent(pagenationToken);
      path = path + '?pagenation_token=' + pagenationToken;
    }
    return this.apiService.get(path, null, false, UrlTypes.Users);
  }

  /**
   * ユーザーの削除
   *
   * @param {string} sub
   * @returns {Promise<any>}
   * @memberof CognitoUtil
   */
  public deleteUser(sub: string): Observable<any> {
    const path = `/users/${sub}`;
    return this.apiService.delete(path, false, UrlTypes.Users);
  }
}
