<div class="clear-button" *ngIf="isEnabledClear()">
  <a (click)="onClickClear()"><mat-icon>clear</mat-icon></a>
</div>

<div class="photo__container">
  <img class="photo__container--image object-fit-image" [lazyLoad]="getThumbImage()" defaultImage="assets/images/loading-image_4x3.png">

  <!-- 前へ戻るボタン -->
  <button mat-fab color="primary" class="chevron chevron-left" (click)="onClickPrevious()" *ngIf="isEnabledPrevious()">
    <mat-icon class="" aria-label="">chevron_left</mat-icon>
  </button>

  <!-- 次へ進むボタン -->
  <button mat-fab color="primary" class="chevron chevron-right" (click)="onClickNext()" *ngIf="isEnabledNext()">
    <mat-icon class="" aria-label="">chevron_right</mat-icon>
  </button>
</div>
