import { Component } from '@angular/core';
import { RevisionService } from './../../services/revision.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-sidenav-footer',
  template: `
    <div class="footer-container" fxLayout="row" fxLayoutAlign="center">
      <div fxLayout="column">
        <p>&copy; {{ copyrightYear }} HANDS LAB INC.</p>
        <p class="about-link">
          <span [routerLink]="['/about']" tabindex="-1">Postforについて</span>
        </p>
      <div>
    </div>
  `,
  styles: [
    `
    /* Footerコンポーネント自身のスタイル */
    :host {
      display: block;
      width: 250px;
      height: 60px;
    }

    p {
      font-size: .6em;
      line-height: 1.4em;
      color: gray;
      text-align: center;
    }

    .about-link:hover {
      color: dimgrey;
      cursor: pointer;
    }
  `,
  ],
})
export class SidenavFooterComponent {
  public copyrightYear: string;
  public appVersion: string;
  public commitVersion: string;

  constructor(private revisionService: RevisionService) {
    this.copyrightYear = '2018';
  }
}
