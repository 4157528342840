import {
  ToggleNonAlbumPostOnly,
  SearchComplete,
} from './../actions/posts.actions';
import { PostsActions, PostsActionTypes } from '../actions/posts.actions';
import { Params } from '@angular/router';

export interface State {
  ids: string[];
  allIds: string[];
  downloadIds: string[];
  total: number;
  loading: boolean;
  searchComplete: boolean;
  searchNextComplete: boolean;
  error: string;
  query: Params;
  nonAlbumPostOnly: boolean;
}

const initialState: State = {
  ids: [],
  allIds: [],
  downloadIds: [],
  total: 0,
  loading: false,
  error: '',
  searchComplete: false,
  searchNextComplete: false,
  query: {},
  nonAlbumPostOnly: false,
};

export function reducer(state = initialState, action: PostsActions): State {
  switch (action.type) {
    case PostsActionTypes.Search: {
      return {
        ...state,
        query: action.payload,
        loading: true,
        searchComplete: false,
      };
    }

    case PostsActionTypes.SearchNext: {
      return {
        ...state,
        query: {
          ...state.query,
          from: action.payload.from,
        },
        loading: true,
        searchNextComplete: false,
      };
    }

    case PostsActionTypes.SearchComplete: {
      return {
        ...state,
        ids: action.payload.map(post => post.post_id),
        loading: false,
        error: '',
        query: state.query,
        searchComplete: true,
      };
    }

    case PostsActionTypes.SearchNextComplete: {
      return {
        ...state,
        ids: action.payload.map(post => post.post_id),
        loading: false,
        error: '',
        searchNextComplete: true,
        query: state.query,
      };
    }

    case PostsActionTypes.SearchError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case PostsActionTypes.SearchCount: {
      return {
        ...state,
        total: action.payload,
      };
    }

    case PostsActionTypes.ToggleNonAlbumPostOnly: {
      return {
        ...state,
        nonAlbumPostOnly: !state.nonAlbumPostOnly,
      };
    }

    case PostsActionTypes.AddIdsToAllIds: {
      return {
        ...state,
        allIds: state.allIds.concat(state.ids),
      };
    }

    case PostsActionTypes.ClearAllIds: {
      return {
        ...state,
        allIds: [],
      };
    }

    case PostsActionTypes.UpdateDownloadIds: {
      return {
        ...state,
        downloadIds: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIds = (state: State) => state.ids;
export const getTotal = (state: State) => state.total;
export const getQuery = (state: State) => state.query;
export const getLoading = (state: State) => state.loading;
export const getSearchComplete = (state: State) => state.searchComplete;
export const getSearchNextComplete = (state: State) => state.searchNextComplete;
export const getError = (state: State) => state.error;
export const getNonAlbumPostOnly = (state: State) => state.nonAlbumPostOnly;
export const getAllIds = (state: State) => state.allIds;
export const getDownloadIds = (state: State) => state.downloadIds;
