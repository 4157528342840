import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'hashtag' })
export class HashtagPipe implements PipeTransform {
    constructor() {}

    transform(text: string){
        let text1;
        const protocol = window.location.protocol;
        const host = window.location.host;
        const baseUrl = `${protocol}//${host}`;
        if (text.indexOf('#') !== -1) {
            text1 = text + ' '; 
            const matches = text1.match(/#(.*?) /g);
            for(let i = 0; i < matches.length; i++){
                const hashtag = matches[i].trim();
                const encodedHashtag = encodeURIComponent(hashtag);
                text1 = text1.replace(matches[i], `<a href="${baseUrl}/search?word=${encodedHashtag}" class="tag-highlighted">${hashtag}</a> `);
            }
        } else {
            text1 = text;
        }
        return text1;
    }
}