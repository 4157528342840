import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Auth } from '../models/auth.model';
import { tap } from 'rxjs/operators';
import { TenantUtil } from '../shared/util/tenant-util';

/**
 * HTTPリクエストのスーパークラス
 * 認証ヘッダーの付加も行う
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authInfo: Auth;

  /**
   * Creates an instance of AuthService.
   * @param {HttpClient} http
   * @memberof AuthService
   */
  constructor(
    private http: HttpClient,
    private tenantUtil: TenantUtil,
  ) {}

  /**
   * 動作環境の認証用のCognitoの情報を取得する
   * 認証の必要が無いため、個別にAPIを呼び出している
   *
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public getAuthInfo(): Observable<Auth> {
    // キャッシュを使う
    if (this.authInfo !== null && this.authInfo instanceof Object) {
      return of(this.authInfo);
    }

    return this.http
      .get<Auth>(`${this.tenantUtil.tenantIdEndpoint}/pools`)
      .pipe(tap(authInfo => (this.authInfo = authInfo)));
  }
}
