import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';

import {
  LoadShops,
  LoadShopsComplete,
  LoadShopsError,
  LoadEvents,
  LoadEventsComplete,
  LoadEventsError,
  LoadCategories,
  LoadCategoriesComplete,
  LoadCategoriesError,
  LoadActualShops,
  LoadActualShopsComplete,
  LoadActualShopsError,
  LoadActualEvents,
  LoadActualEventsComplete,
  LoadActualEventsError,
  LoadActualCategories,
  LoadActualCategoriesComplete,
  LoadActualCategoriesError,
  LoadOtherError,
} from './../actions/master.actions';
import { MasterActions, MasterActionTypes } from '../actions/master.actions';
import { ParametersService } from './../../services/parameters.service';
import { Shop, Event, Category, ShopGroup } from './../../models';

@Injectable()
export class MasterEffects {
  
  fetchShops$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadShops>(MasterActionTypes.LoadShops),
    switchMap(() =>
      this.parametersService
        .getShops()
        .pipe(
          map((shops: Shop[]) => new LoadShopsComplete(shops)),
          catchError(error => of(new LoadShopsError(error)))
        )
    )
  ));

  
  fetchEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadEvents>(MasterActionTypes.LoadEvents),
    switchMap(() =>
      this.parametersService
        .getEvents()
        .pipe(
          map((events: Event[]) => new LoadEventsComplete(events)),
          catchError(error => of(new LoadEventsError(error)))
        )
    )
  ));

  
  fetchCategories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadCategories>(MasterActionTypes.LoadCategories),
    switchMap(() =>
      this.parametersService
        .getCategories()
        .pipe(
          map(
            (categories: Category[]) => new LoadCategoriesComplete(categories)
          ),
          catchError(error => of(new LoadCategoriesError(error)))
        )
    )
  ));

  
  httpError$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<any>(
      MasterActionTypes.LoadShopsError,
      MasterActionTypes.LoadEventsError,
      MasterActionTypes.LoadCategoriesError,
      MasterActionTypes.LoadActualShopsError,
      MasterActionTypes.LoadActualEventsError,
      MasterActionTypes.LoadActualCategoriesError
    ),
    map(action => action.payload),
    switchMap((error: HttpErrorResponse) => {
      // console.log(error);
      if (error.message === 'Timeout has occurred') {
        this.snackBar.open(
          '通信エラーが発生しました。しばらく経ってから再度実行してください。',
          '',
          { duration: 3000 }
        );
      } else {
        this.snackBar.open(
          'エラーが発生しました。しばらく経ってから再度実行してください。',
          '',
          { duration: 3000 }
        );
      }
      return of(new LoadOtherError(error));
    })
  ));

  constructor(
    private actions$: Actions,
    private parametersService: ParametersService,
    private snackBar: MatSnackBar
  ) {}
}
