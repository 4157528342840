import { Action } from '@ngrx/store';
import { LoadingActions, LoadingActionTypes } from '../actions/loading.actions';

export interface State {
  loading: boolean;
}

export const initialState: State = {
  loading: false,
};

export function reducer(state = initialState, action: LoadingActions): State {
  switch (action.type) {
    case LoadingActionTypes.StartAnimating: {
      return {
        loading: true,
      };
    }

    case LoadingActionTypes.StopAnimating: {
      return {
        loading: false,
      };
    }

    default:
      return state;
  }
}

export const getLoading = (state: State) => state.loading;
