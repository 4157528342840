<mat-card class="image-preview-card" [ngClass]="{'image-preview-card--over': dropZoneOber}" 

  >
  <div file-drop-zone [control]="control">

  <!-- 画像が選択されている時 -->
  <app-image-preview [thumbImages]="images" [currentImageIndex]="currentImageIndex" (changeThumbImages)="onChangePreview($event)" *ngIf="isSelectedImg() && !isImageEditing"></app-image-preview>

  <!-- 未選択時のカメラアイコン表示 -->
  <div class="cameraicon__container" [ngClass.lt-sm]="{'cameraicon__container--mobile': true}" *ngIf="!isSelectedImg() || isImageEditing">
    <div class="image-upload">
      <label for="file-input">
        <mat-icon class="cameraicon" color="primary" [ngStyle]="noImageSelected()">camera_alt</mat-icon>
        <!-- 画面サイズに合わせて文言を変更 モバイル -->
        <div class="cameraicon-caption__container" fxHide.gt-xs>
          <p class="cameraicon-caption__item" [ngStyle]="noImageSelected()">タップして投稿する写真を選択</p>
        </div>
        <!-- 画面サイズに合わせて文言を変更 PC-->
        <div class="cameraicon-caption__container" fxHide.lt-sm>
          <p class="cameraicon-caption__item" [ngStyle]="noImageSelected()">クリックして投稿する写真を選択</p>
          <p class="cameraicon-caption__item cameraicon-caption__item--sub" [ngStyle]="noImageSelected()">または</p>
          <p class="cameraicon-caption__item" [ngStyle]="noImageSelected()">ドラッグ&ドロップしてください</p>
          <p class="cameraicon-caption__item cameraicon-caption__item--sub cameraicon-caption__item--size">最大アップロードサイズ: {{ maxImageUploadMB }}MB (1枚あたり)</p>
        </div>
      </label>
      <input id="file-input" type="file" accept="image/png,image/jpeg" (change)="handleInputChange($event)" multiple/>
    </div>
  </div>
</div>
</mat-card>


<div class="menu-fabs" [ngClass.lt-sm]="{'menu-fabs--mobile': true}" *ngIf="isSelectedImg()">
  <!-- 画像追加ボタン -->
  <div class="add-image-fab">
    <button mat-fab color="primary" matTooltip="画像を追加" (click)="fileInput.click()">
      <mat-icon>add</mat-icon>
    </button>
    <input id="file-input" #fileInput type="file" accept="image/png,image/jpeg" (change)="handleInputChange($event)" multiple/>
  </div>


  <!-- 画像編集時時のfab -->
  <div class="edit-image-fab">
    <button mat-fab color="primary" matTooltip="画像を編集" (click)="onClickEditImage()">
      <mat-icon>mode_edit</mat-icon>
    </button>
  </div>
</div>
