import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserLoginService } from './../../../services';

@Component({
  selector: 'app-logout',
  template: ' ',
})
export class LogoutComponent implements OnInit {
  constructor(public router: Router, public userService: UserLoginService) {}

  ngOnInit() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }
}
