<!-- ツールバー -->
<app-toolbar
  [pageTitle]="pageTitle"
  [style.top.px]="toolBarPos"
  (clickSearchIconButton)="toggleMobileSearchForm($event)"
  [openMobileSearch]="showMobileSearchForm"
  [showSearchForm]="showSearchForm"
  >
</app-toolbar>

<!-- モバイルの検索ボックス -->
<mat-toolbar class="mobile-search-wapper" color="primary" *ngIf="showMobileSearchForm">
  <app-search fxHide.gt-xs (close)="onMobileSearch()"></app-search>
</mat-toolbar>

<!-- 詳細検索ボックス -->
<app-search-form (clickOutside)="onClickOutside($event)" @panelOpenTrigger *ngIf="showSearchForm$ | async"
  [ngClass]="{'app-search-form-mobile': showMobileSearchForm}">
</app-search-form>

<mat-sidenav-container class="app-inner">

  <!-- サイドナビゲーション -->
  <mat-sidenav class="sidebar-panel" #sidenav [mode]="sidenavMode$ | async" [opened]="showSidenav$ | async">
    <div style="height: 58px;"></div>
    <mat-nav-list>
      <mat-list-item (click)="onClickMenuItem('/')" routerLinkActive="active">
        <mat-icon class="list-item-icon">home</mat-icon>
        <span class="list-item-text">ホーム</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item (click)="onClickMenuItem('/album')" routerLinkActive="active">
        <mat-icon class="list-item-icon">photo_library</mat-icon>
        <span class="list-item-text">アルバム</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item (click)="onClickMenuItem('/notifications')" routerLinkActive="active">
        <mat-icon class="list-item-icon">notifications</mat-icon>
        <span class="list-item-text">お知らせ</span>
        <span class="notification-label" *ngIf="(unreadCount$ | async) > 0">{{ unreadCount$ | async }}</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item (click)="onClickMenuItem('/upload')" routerLinkActive="active">
        <mat-icon class="list-item-icon">camera_alt</mat-icon>
        <span class="list-item-text">投稿</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>
    <div class="list-item-category">
      <span>アカウント</span>
      <span fxFlex></span>
    </div>
    <mat-nav-list>
      <mat-list-item (click)="onClickMenuItem('/userinfo')" routerLinkActive="active">
        <mat-icon class="list-item-icon">person</mat-icon>
        <span class="list-item-text">ユーザー情報</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item (click)="onClickMenuItem('/logout')">
        <mat-icon class="list-item-icon">lock_open</mat-icon>
        <span class="list-item-text">ログアウト</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickMenuItem('/admin/userlist')" routerLinkActive="active">
        <mat-icon class="list-item-icon">assignment_ind</mat-icon>
        <span class="list-item-text">ユーザー管理</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>

    <div *ngIf="isAdmin$ | async" class="list-item-category">
        <span>マスタ管理</span>
        <span fxFlex></span>
      </div>
      <mat-nav-list *ngIf="isAdmin$ | async">
         <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickMenuItem('/master/store-list')" routerLinkActive="active">
              <mat-icon class="list-item-icon">playlist_add</mat-icon>
              <span class="list-item-text">店舗</span>
              <span fxFlex></span>
          </mat-list-item>
          <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickMenuItem('/master/storegr-list')" routerLinkActive="active">
            <mat-icon class="list-item-icon">playlist_add</mat-icon>
            <span class="list-item-text">店舗グループ</span>
            <span fxFlex></span>
          </mat-list-item>
          <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickMenuItem('/master/event-list')" routerLinkActive="active">
            <mat-icon class="list-item-icon">playlist_add</mat-icon>
            <span class="list-item-text">イベント</span>
            <span fxFlex></span>
        </mat-list-item>
          <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickMenuItem('/master/department-list')" routerLinkActive="active">
                <mat-icon class="list-item-icon">playlist_add</mat-icon>
                <span class="list-item-text">部門</span>
                <span fxFlex></span>
          </mat-list-item>
          <mat-divider></mat-divider>
      </mat-nav-list>

    <div class="list-item-category">
      <span>マニュアル</span>
      <span fxFlex></span>
    </div>
    <mat-nav-list>
      <mat-list-item (click)="onClickManualDownload('user')">
        <mat-icon class="list-item-icon">description</mat-icon>
        <span class="list-item-text">ユーザー向け</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-list-item *ngIf="isAdmin$ | async" (click)="onClickManualDownload('admin')">
        <mat-icon class="list-item-icon">description</mat-icon>
        <span class="list-item-text">管理者向け</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>

    <div class="list-item-category">
      <span>お問い合わせ</span>
      <span fxFlex></span>
    </div>
    <mat-nav-list>
      <mat-list-item (click)="onClickMenuItem('/contact')" routerLinkActive="active">
        <mat-icon class="list-item-icon">mail</mat-icon>
        <span class="list-item-text">お問い合わせ</span>
        <span fxFlex></span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>

    <app-sidenav-footer></app-sidenav-footer>
  </mat-sidenav>

  <!-- メインコンテンツ -->
  <router-outlet></router-outlet>
</mat-sidenav-container>

<!-- スクロール量とルーティング状態に応じてボタンを出し分ける -->
<div matTooltip="写真を投稿" matTooltipPosition="above" class="upload-fab" ngClass.gt-xs="upload-fab-gt-xs" *ngIf="isHome() && !showScrollTopButton">
  <a mat-fab (click)="onClickMenuItem('/upload')" color="primary">
    <mat-icon>camera_alt</mat-icon>
  </a>
</div>

<div matTooltip="アルバムを追加" matTooltipPosition="above" class="upload-fab" ngClass.gt-xs="upload-fab-gt-xs" *ngIf="isAlbum() && !showScrollTopButton">
  <a mat-fab (click)="onClickMenuItem('/album/new')" color="primary">
    <mat-icon>photo_library</mat-icon>
  </a>
</div>

<div matTooltip="ページトップへ戻る" matTooltipPosition="above" class="upload-fab" ngClass.gt-xs="upload-fab-gt-xs" *ngIf="showScrollTopButton">
  <a mat-fab (click)="onClickScrollToTop()" color="primary">
    <mat-icon>vertical_align_top</mat-icon>
  </a>
</div>
