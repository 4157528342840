import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div class="loading-indicator">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50" strokeWidth="5"></mat-progress-spinner>
    </div>
  `,
  styles: [
    `
      mat-progress-spinner {
        margin: 0 auto;
      }
    `,
  ],
})
export class LoadingComponent {
  constructor() {}
}
