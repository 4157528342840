<div fxLayout="row" fxLayoutAlign="center">
  <mat-card  class="not-found-card" fxFlex="60%" fxFlex.lt-sm="100%">
    <mat-card-title >指定されたページは見つかりません。</mat-card-title>
    <mat-card-content>
      <p>エラーコード: 404</p>
      <a href="/">{{ appName }} Home</a>
    </mat-card-content>
    <mat-card-footer>
      <div class="button-row">
        <button mat-raised-button color="primary" (click)="onClickBack()">戻る</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
