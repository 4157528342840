import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatter } from './../util/date-formatter';

@Pipe({
  name: 'jstFull',
})
export class JstFullPipe implements PipeTransform {
  constructor(private dateFormatter: DateFormatter) {}

  transform(timestamp: string): string {
    return timestamp ? this.dateFormatter.toJstFull(timestamp) : '';
  }
}
