import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { CognitoUtil } from './cognito.service';
import * as fromRoot from './../reducers';
import * as loading from './../core/actions/loading.actions';
import { environment } from '../../environments/environment';
import { TenantUtil } from '../shared/util/tenant-util';
import { UrlTypes } from '../shared/util/tenant-util';

/**
 * HTTPリクエストのスーパークラス
 * 認証ヘッダーの付加も行う
 *
 * @export
 * @class ApiService
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private timeoutMs = environment.timeoutMs;

  /**
   * Creates an instance of ApiService.
   * @param {HttpClient} http
   * @param {CognitoUtil} cognitoUtil
   * @param {TenantUtil} tenantUtil
   * @param {LoadingService} loadingService
   * @memberof ApiService
   */
  constructor(
    private http: HttpClient,
    public cognitoUtil: CognitoUtil,
    private tenantUtil: TenantUtil,
    private store: Store<fromRoot.State>
  ) {}

  /**
   * CognitoIdTokenを取得
   *
   * @private
   * @returns {Observable<string>}
   * @memberof ApiService
   */
  private getToken(): Observable<string> {
    return this.cognitoUtil.getIdToken();
  }

  /**
   * リクエストヘッダーを作る
   *
   * @private
   * @param {string} token
   * @returns {HttpHeaders}
   * @memberof ApiService
   */
  private setHeaders(token: string): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    };
    return new HttpHeaders(headersConfig);
  }

  /**
   * 画像ポスト時のリクエストヘッダーを作る
   *
   * @private
   * @param {string} token
   * @returns {HttpHeaders}
   * @memberof ApiService
   */
  private setBinaryHeaders(token: string): HttpHeaders {
    const headersConfig = {
      Authorization: token,
    };
    return new HttpHeaders(headersConfig);
  }

  /**
   * 正常レスポインスを取り回す
   *
   * @private
   * @param {HttpResponse<any>} res
   * @returns
   * @memberof ApiService
   */
  private extractData(res: HttpResponse<any>) {
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`Bad response stutus: ${res.status}`);
    } else {
      return res.body || {};
    }
  }

  /**
   * サービスのエラーハンドラ
   * 各種サービスのエラーハンドラでもエラーを使いまわせるようにthrow
   *
   * @private
   * @param {HttpErrorResponse} error
   * @returns
   * @memberof ApiService
   */
  private handleError(error: HttpErrorResponse) {
    // エラーのときにはローディング表示を強制停止
    this.store.dispatch(new loading.StopAnimating());
    return observableThrowError(error);
  }

  /**
   * GETリクエスト
   *
   * @param {string} path
   * @param {HttpParams} [params=new HttpParams()]
   * @param {boolean} [disableLoading]
   * @param {UrlType} [UrlTypes]
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  public get<T>(
    path: string,
    params: HttpParams = new HttpParams(),
    disableLoading?: boolean,
    UrlType?: UrlTypes
  ): Observable<T> {
    return this.getToken().pipe(
      tap(
        () =>
          !disableLoading && this.store.dispatch(new loading.StartAnimating())
      ),
      mergeMap(token => {
        return this.http
          .get<T>(`${this.tenantUtil.apiEndpoint}${path}`, {
            headers: this.setHeaders(token),
            params: params,
            observe: 'response',
          })
          .pipe(
            timeout(this.timeoutMs),
            map(this.extractData),
            tap(x => this.store.dispatch(new loading.StopAnimating())),
            catchError(error => this.handleError(error))
          );
      }),
      take(1)
    );
  }

  /**
   * PUTリクエスト
   *
   * @param {string} path
   * @param {Object} [body={}]
   * @param {boolean} [disableLoading]
   * @param {UrlType} [UrlTypes]
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  public put<T>(
    path: string,
    body: Object = {},
    disableLoading?: boolean,
    UrlType?: UrlTypes
  ): Observable<T> {
    return this.getToken().pipe(
      tap(
        () =>
          !disableLoading && this.store.dispatch(new loading.StartAnimating())
      ),
      mergeMap(token => {
        return this.http
          .put<T>(
            `${this.tenantUtil.apiEndpoint}${path}`,
            JSON.stringify(body),
            {
              headers: this.setHeaders(token),
              observe: 'response',
            }
          )
          .pipe(
            timeout(this.timeoutMs),
            map(this.extractData),
            tap(x => this.store.dispatch(new loading.StopAnimating())),
            catchError(error => this.handleError(error))
          );
      }),
      take(1)
    );
  }

  /**
   * POSTリクエスト
   *
   * @param {string} path
   * @param {Object} [body={}]
   * @param {boolean} [disableLoading]
   * @param {UrlType} [UrlTypes]
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  public post<T>(
    path: string,
    body: Object = {},
    disableLoading?: boolean,
    UrlType?: UrlTypes
  ): Observable<T> {
    return this.getToken().pipe(
      tap(
        () =>
          !disableLoading && this.store.dispatch(new loading.StartAnimating())
      ),
      mergeMap(token => {
        return this.http
          .post<T>(
            `${this.tenantUtil.apiEndpoint}${path}`,
            JSON.stringify(body),
            {
              headers: this.setHeaders(token),
              observe: 'response',
            }
          )
          .pipe(
            timeout(this.timeoutMs),
            map(this.extractData),
            tap(x => this.store.dispatch(new loading.StopAnimating())),
            catchError(error => this.handleError(error))
          );
      }),
      take(1)
    );
  }

  /**
   * POSTリクエスト(bodyにバイナリ)
   *
   * @param {string} path
   * @param {*} body
   * @param {boolean} [disableLoading]
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  public postBinary<T>(
    path: string,
    body: any,
    disableLoading?: boolean
  ): Observable<T> {
    return this.getToken().pipe(
      tap(
        () =>
          !disableLoading && this.store.dispatch(new loading.StartAnimating())
      ),
      mergeMap(token => {
        return this.http
          .post<T>(`${this.tenantUtil.apiEndpoint}${path}`, body, {
            headers: this.setBinaryHeaders(token),
            observe: 'response',
          })
          .pipe(
            timeout(this.timeoutMs),
            map(this.extractData),
            tap(x => this.store.dispatch(new loading.StopAnimating())),
            catchError(error => this.handleError(error))
          );
      }),
      take(1)
    );
  }

  /**
   * DELETEリクエスト
   *
   * @param {string} path
   * @param {boolean} [disableLoading]
   * @param {UrlType} [UrlTypes]
   * @returns {Observable<any>}
   *
   * @memberof ApiService
   */
  public delete<T>(
    path: string,
    disableLoading?: boolean,
    UrlType?: UrlTypes
  ): Observable<T> {
    return this.getToken().pipe(
      tap(
        () =>
          !disableLoading && this.store.dispatch(new loading.StartAnimating())
      ),
      mergeMap(token => {
        return this.http
          .delete<T>(`${this.tenantUtil.apiEndpoint}${path}`, {
            headers: this.setHeaders(token),
            observe: 'response',
          })
          .pipe(
            timeout(this.timeoutMs),
            map(this.extractData),
            tap(x => this.store.dispatch(new loading.StopAnimating())),
            catchError(error => this.handleError(error))
          );
      }),
      take(1)
    );
  }
}
