import { Media } from './media.model';

export class Comment {
  post_id?: string;
  comment_id?: number;
  comment: string;
  user_id?: string;
  user_name?: string;
  shop_id?: string;
  shop_name?: string;
  datetime?: string;
  parent_comment_id?: number;
  media?: Media;
}
