import { Action } from '@ngrx/store';

export enum LoadingActionTypes {
  StartAnimating = '[Loading] Start Animating',
  StopAnimating = '[Loading] Stop Animating',
}

export class StartAnimating implements Action {
  readonly type = LoadingActionTypes.StartAnimating;
}

export class StopAnimating implements Action {
  readonly type = LoadingActionTypes.StopAnimating;
}

export type LoadingActions = StartAnimating | StopAnimating;
