<div class="page__wapper">
  <div class="page__container" fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="60%" fxFlex.lt-sm="100%">
      <div fxLayout="column">
        <!-- logo -->
        <img class="main-logo" fxHide.lt-sm src="assets/posfor_big_logo.svg" alt="postfor" width="140">
        <img class="main-logo" fxHide.gt-xs src="assets/posfor_big_mobile_logo.svg" alt="postfor" width="140">
        <!-- mainカード -->
        <router-outlet></router-outlet>
      </div>
      <!-- コピーライト -->
      <app-card-under-copyright></app-card-under-copyright>
    </div>
  </div>
</div>
