import { Action } from '@ngrx/store';
import { LayoutActions, LayoutActionTypes } from '../actions/layout.actions';

export interface State {
  // サイドナビゲーション(表示/非表示)
  showSidenav: boolean;
  // サイドナビゲーション(表示モードの切り替え)
  sidenavMode: string;
  // 詳細検索フォーム(表示/非表示)
  showSearchForm: boolean;
}

const initialState: State = {
  showSidenav: false,
  sidenavMode: 'over',
  showSearchForm: false,
};

export function reducer(
  state: State = initialState,
  action: LayoutActions
): State {
  switch (action.type) {
    case LayoutActionTypes.CloseSidenav:
      return {
        ...state,
        showSidenav: false,
      };

    case LayoutActionTypes.OpenSidenav:
      return {
        ...state,
        showSidenav: true,
      };

    case LayoutActionTypes.OverSidenav:
      return {
        ...state,
        sidenavMode: 'over',
      };

    case LayoutActionTypes.SideSidenav:
      return {
        ...state,
        sidenavMode: 'side',
      };

    case LayoutActionTypes.CloseSearchForm:
      return {
        ...state,
        showSearchForm: false,
      };

    case LayoutActionTypes.OpenSearchForm:
      return {
        ...state,
        showSearchForm: true,
      };

    default:
      return state;
  }
}

export const getShowSidenav = (state: State) => state.showSidenav;
export const getSidenavMode = (state: State) => state.sidenavMode;
export const getShowSearchForm = (state: State) => state.showSearchForm;
