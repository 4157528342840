export const environment = {
  production: false,

  // general
  appName: 'Postfor',
  appVersion: '1.0.0',

  // API DOMAIN
  env: 'dev',

  // cognito
  region: 'ap-northeast-1',

  // usergroup
  groups: {
    adminGroup: { key: 'tenant_admin', displayName: '管理者'},
    clientGroup: { key: 'tenant_user', displayName: '一般ユーザー'},
    svGroup: { key: 'tenant_sv', displayName: 'SVユーザー'},
  },

  // elasticsearch
  fetchSize: 96,
  status: { ACTIVE: 'published', INACTIVE: 'deleted' },
  freewordFields: [
    'description',
    'tags',
    'shop.name',
    'event.name',
    'category.name',
    'album.name',
    'user.name',
  ],
  searchAllParam: '',
  local_searchQueryParam: 'searchQueryParam',

  // infinite scroll
  scrollDistance: 1,
  throttle: 300,

  // search history
  local_searchQueryParams: 'searchQueryParams',
  historyCount: 5,

  // image upload size (MB)
  maxImageUploadMB: 6,
  // api timeout
  timeoutMs: 29000,

};
