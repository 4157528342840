import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { CognitoUtil } from './cognito.service';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { Store, select } from '@ngrx/store';

import { UserParametersService } from './user-parameters.service';
import * as fromRoot from './../reducers';
import * as loading from './../core/actions/loading.actions';
import * as auth from './../core/actions/auth.actions';
import { environment } from './../../environments/environment';

import { tap, take, map, switchMap } from 'rxjs/operators';

/**
 * ログイン
 *
 * @export
 * @class UserLoginService
 */
@Injectable({
  providedIn: 'root'
})
export class UserLoginService {
  /**
   * Creates an instance of UserLoginService.
   * @param {CognitoUtil} cognitoUtil
   * @memberof UserLoginService
   */
  constructor(
    public cognitoUtil: CognitoUtil,
    private userParametersService: UserParametersService,
    private store: Store<fromRoot.State>
  ) {}

  /**
   * ログイン時の認証
   *
   * @param {string} username
   * @param {string} password
   * @returns {Observable<any>}
   * @memberof UserLoginService
   */
  public authenticate(username: string, password: string): Observable<any> {
    return this.cognitoUtil.getUserPool().pipe(
      map(
        pool =>
          new CognitoUser({
            Username: username,
            Pool: pool
          })
      ),
      switchMap(user => {
        const authenticationData = {
          Username: username,
          Password: password
        };

        const authenticationDetails = new AuthenticationDetails(
          authenticationData
        );

        return Observable.create((observer: Observer<any>) =>
          user.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => {
              this.store.dispatch(new loading.StopAnimating());
              observer.error({
                error: `User needs to set password.`,
                result: null
              });
            },
            onSuccess: (result: CognitoUserSession) => {
              this.store.dispatch(new loading.StopAnimating());
              observer.next({ error: null, result: result });
            },
            onFailure: (error: Error) => {
              this.store.dispatch(new loading.StopAnimating());
              observer.error({ error: error.message, result: null });
            }
          })
        );
      }),
      tap((obj: any) => {
        // Storeにdispatch
        this.store.dispatch(
          new auth.SetUserSession({
            jwtToken: obj.result.getIdToken().getJwtToken(),
            refreshToken: obj.result.getRefreshToken().getToken()
          })
        );
      }),
      switchMap((obj: any) =>
        this.cognitoUtil.buildCognitoCreds(
          obj.result.getIdToken().getJwtToken(),
          username
        )
      ),
      tap(creds => {
        AWS.config.credentials = creds;
      })
    );
  }

  /**
   * パスワード忘れ(Step1)
   *
   * @param {string} username
   * @returns {Observable<any>}
   * @memberof UserLoginService
   */
  public forgotPassword(username: string): Observable<any> {
    this.store.dispatch(new loading.StartAnimating());

    return this.cognitoUtil.getUserPool().pipe(
      map(
        pool =>
          new CognitoUser({
            Username: username,
            Pool: pool
          })
      ),
      switchMap(user =>
        Observable.create((observer: Observer<any>) =>
          user.forgotPassword({
            onSuccess: () => {
              this.store.dispatch(new loading.StopAnimating());
            },
            onFailure: (error: Error) => {
              this.store.dispatch(new loading.StopAnimating());
              observer.error({ error: error.message, result: null });
            },
            inputVerificationCode: () => {
              this.store.dispatch(new loading.StopAnimating());
              observer.next({ error: null, result: null });
            }
          })
        )
      )
    );
  }

  /**
   * パスワード忘れ(Step2)
   *
   * @param {string} email
   * @param {string} verificationCode
   * @param {string} password
   * @returns {Observable<any>}
   * @memberof UserLoginService
   */
  public confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string
  ): Observable<any> {
    this.store.dispatch(new loading.StartAnimating());

    return this.cognitoUtil.getUserPool().pipe(
      map(
        pool =>
          new CognitoUser({
            Username: email,
            Pool: pool
          })
      ),
      switchMap(user =>
        Observable.create((observer: Observer<any>) =>
          user.confirmPassword(verificationCode, password, {
            onSuccess: () => {
              this.store.dispatch(new loading.StopAnimating());
              observer.next({ error: null, result: null });
            },
            onFailure: (error: Error) => {
              this.store.dispatch(new loading.StopAnimating());
              observer.error({ error: error.message, result: null });
            }
          })
        )
      )
    );
  }

  /**
   * ログアウト処理
   * ユーザーパラメータを保持するサービスのキャッシュをクリアしないと再ログイン時に別ユーザー情報が残り続ける
   *
   * @memberof UserLoginService
   */
  public logout() {
    this.cognitoUtil
      .getCurrentUser()
      .pipe(
        tap(user => {
          user.signOut();
          sessionStorage.clear();
          this.userParametersService.refreshUserInfo();
        }),
        take(1)
      )
      .subscribe(() => {});
    // ローカルストレージのアルバム検索情報を削除
    localStorage.removeItem('filteringCondition');
  }

  /**
   * ユーザーのセッションチェック
   *
   * @returns {Observable<boolean>}
   * @memberof UserLoginService
   */
  public isAuthenticated(): Observable<boolean | any> {
    return this.cognitoUtil.getCurrentUser().pipe(
      switchMap(user =>
        Observable.create((observer: Observer<any>) => {
          if (user !== null) {
            user.getSession((error, session) => {
              if (error) {
                observer.error(error);
              } else {
                observer.next(session.isValid());
              }
            });
          } else {
            observer.next(false);
          }
        })
      )
    );
  }
}
