import { Action } from '@ngrx/store';

import { Comment } from '../../models';

export enum CommentsActionTypes {
  LoadComments = '[Comments] Load Comments',
  LoadCommentsComplete = '[Comments] Load Comments Complete',
  LoadCommentsError = '[Comments] Load Comments Error',
  AddComment = '[Comments] Add Comment',
  AddCommentComplete = '[Comments] Add Comment Complete',
  AddCommentError = '[Comments] Add Comment Error',
  DeleteComment = '[Comments] Delete Comment',
  DeleteCommentComplete = '[Comments] Delete Comment Complete',
  DeleteCommentError = '[Comments] Delete Comment Error',
  UpdateComment = '[Comments] Update Comment',
  UpdateCommentComplete = '[Comments] Update Comment Complete',
  UpdateCommentError = '[Comments] Update Comment Error',
  OtherCommentError = '[Comments] Update Comment Error',
}

export class LoadComments implements Action {
  readonly type = CommentsActionTypes.LoadComments;

  constructor(public payload: string) {}
}

export class LoadCommentsComplete implements Action {
  readonly type = CommentsActionTypes.LoadCommentsComplete;

  constructor(public payload: Comment[]) {}
}

export class LoadCommentsError implements Action {
  readonly type = CommentsActionTypes.LoadCommentsError;

  constructor(public payload: any) {}
}

export class AddComment implements Action {
  readonly type = CommentsActionTypes.AddComment;

  constructor(public payload: { post_id: string; comment: Comment }) {}
}

export class AddCommentComplete implements Action {
  readonly type = CommentsActionTypes.AddCommentComplete;

  constructor(public payload: Comment) {}
}

export class AddCommentError implements Action {
  readonly type = CommentsActionTypes.AddCommentError;

  constructor(public payload: any) {}
}

export class DeleteComment implements Action {
  readonly type = CommentsActionTypes.DeleteComment;

  constructor(public payload: { post_id: string; comment_id: number }) {}
}

export class DeleteCommentComplete implements Action {
  readonly type = CommentsActionTypes.DeleteCommentComplete;

  constructor(public payload: string) {}
}

export class DeleteCommentError implements Action {
  readonly type = CommentsActionTypes.DeleteCommentError;

  constructor(public payload: any) {}
}

export class UpdateComment implements Action {
  readonly type = CommentsActionTypes.UpdateComment;

  constructor(public payload: { post_id: string; comment: Comment }) {}
}

export class UpdateCommentComplete implements Action {
  readonly type = CommentsActionTypes.UpdateCommentComplete;

  constructor(public payload: Comment) {}
}

export class UpdateCommentError implements Action {
  readonly type = CommentsActionTypes.UpdateCommentError;

  constructor(public payload: any) {}
}

export class OtherCommentError implements Action {
  readonly type = CommentsActionTypes.OtherCommentError;

  constructor(public payload: any) {}
}

export type CommentsActions =
  | LoadComments
  | LoadCommentsComplete
  | LoadCommentsError
  | AddComment
  | AddCommentComplete
  | AddCommentError
  | DeleteComment
  | DeleteCommentComplete
  | DeleteCommentError
  | UpdateComment
  | UpdateCommentComplete
  | UpdateCommentError;
