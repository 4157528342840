import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  LoadNotificationCount,
  LoadNotificationCountComplete,
  LoadNotificationCountError,
  MarkReadNotifications,
  MarkReadNotificationsComplete,
  MarkReadNotificationsError,
  MarkReadOtherError,
} from '../actions/notification-count.actions';
import {
  NotificationCountActions,
  NotificationCountActionTypes,
} from '../actions/notification-count.actions';
import {
  Notification,
  Notifications,
  NotificationQueryParams,
} from './../../models';

import { NotificationsService } from '../../core/services/notifications.service';

@Injectable()
export class NotificationCountEffects {
  
  loadNotificationCount$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadNotificationCount>(
      NotificationCountActionTypes.LoadNotificationCount
    ),
    switchMap(() =>
      this.notificationsService
        .fetchUnreadCount()
        .pipe(
          map((count: number) => new LoadNotificationCountComplete(count)),
          catchError(error => of(new LoadNotificationCountError(error)))
        )
    )
  ));

  
  markReadNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<MarkReadNotifications>(
      NotificationCountActionTypes.MarkReadNotifications
    ),
    switchMap(() =>
      this.notificationsService
        .markRead()
        .pipe(
          map((result: boolean) => new MarkReadNotificationsComplete(result)),
          catchError(error => of(new MarkReadNotificationsError(error)))
        )
    )
  ));

  
  httpError$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<any>(
      NotificationCountActionTypes.LoadNotificationCountError,
      NotificationCountActionTypes.MarkReadNotificationsError
    ),
    map(action => action.payload),
    switchMap((error: HttpErrorResponse) => {
      // console.log(error);
      if (error.message === 'Timeout has occurred') {
        this.snackBar.open(
          '通信エラーが発生しました。しばらく経ってから再度実行してください。',
          '',
          { duration: 3000 }
        );
      } else {
        this.snackBar.open(
          'エラーが発生しました。しばらく経ってから再度実行してください。',
          '',
          { duration: 3000 }
        );
      }
      return of(new MarkReadOtherError(error.message));
    })
  ));

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar
  ) {}
}
