import { Action } from '@ngrx/store';
import { MasterActions, MasterActionTypes } from '../actions/master.actions';
import { Shop, Event, Category, ShopGroup } from './../../models';

export interface State {
  shops: Shop[];
  actualShops: Shop[];
  events: Event[];
  actualEvents: Event[];
  categories: Category[];
  actualCategories: Category[];
}

export const initialState: State = {
  shops: [],
  actualShops: [],
  events: [],
  actualEvents: [],
  categories: [],
  actualCategories: [],
};

export function reducer(state = initialState, action: MasterActions): State {
  switch (action.type) {
    case MasterActionTypes.LoadShopsComplete: {
      return {
        ...state,
        shops: action.payload,
      };
    }

    case MasterActionTypes.LoadEventsComplete: {
      return {
        ...state,
        events: action.payload,
      };
    }

    case MasterActionTypes.LoadCategoriesComplete: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    case MasterActionTypes.LoadActualShopsComplete: {
      return {
        ...state,
        actualShops: action.payload,
      };
    }

    case MasterActionTypes.LoadActualShopsComplete: {
      return {
        ...state,
        actualShops: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getShops = (state: State) => state.shops;
export const getEvents = (state: State) => state.events;
export const getCategories = (state: State) => state.categories;
export const getActualShops = (state: State) => state.actualShops;
export const getActualEvents = (state: State) => state.actualEvents;
export const getActualCategories = (state: State) => state.actualCategories;
