import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { RouterStateUrl } from './../shared/util/router-state-serializer';

import * as fromRouter from '@ngrx/router-store';
import * as fromLayout from './../core/reducers/layout.reducer';
import * as fromAuth from './../core/reducers/auth.reducer';
import * as fromLoading from './../core/reducers/loading.reducer';
import * as fromMaster from './../core/reducers/master.reducer';
import * as fromSearch from './../core/reducers/search.reducer';
import * as fromNotificationCount from './../notifications/reducers/notification-count.reducer';

export interface State {
  layout: fromLayout.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  auth: fromAuth.State;
  loading: fromLoading.State;
  master: fromMaster.State;
  search: fromSearch.State;
  notificationCount: fromNotificationCount.State;
}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  loading: fromLoading.reducer,
  master: fromMaster.reducer,
  search: fromSearch.reducer,
  notificationCount: fromNotificationCount.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

/**
 * Layout Selector
 */
export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');

export const getShowSidenav = createSelector(
  getLayoutState,
  fromLayout.getShowSidenav
);

export const getShowSearchForm = createSelector(
  getLayoutState,
  fromLayout.getShowSearchForm
);

export const getSidenavMode = createSelector(
  getLayoutState,
  fromLayout.getSidenavMode
);

/**
 * Auth Selector
 */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const getUser = createSelector(getAuthState, fromAuth.getUser);

export const getIsAdmin = createSelector(getAuthState, fromAuth.getIsAdmin);

export const getIsSv = createSelector(getAuthState, fromAuth.getIsSv);

export const getMediaSignature = createSelector(
  getAuthState,
  fromAuth.getMediaSignature
);

export const getJwt = createSelector(getAuthState, fromAuth.getJwt);

/**
 * Loading Selector
 */
export const getLoadingState = createFeatureSelector<fromLoading.State>(
  'loading'
);

export const getLoading = createSelector(
  getLoadingState,
  fromLoading.getLoading
);

/**
 * Master Selector
 */
export const getMasterState = createFeatureSelector<fromMaster.State>('master');

export const getShops = createSelector(getMasterState, fromMaster.getShops);

export const getEvents = createSelector(getMasterState, fromMaster.getEvents);

export const getCategories = createSelector(
  getMasterState,
  fromMaster.getCategories
);

export const getActualShops = createSelector(
  getMasterState,
  fromMaster.getActualShops
);

export const getActualEvents = createSelector(
  getMasterState,
  fromMaster.getActualEvents
);

export const getActualCategories = createSelector(
  getMasterState,
  fromMaster.getActualCategories
);

/**
 * Search Selector
 */
export const getSearchFilterState = createFeatureSelector<fromSearch.State>(
  'search'
);

export const GetSearchFilter = createSelector(
  getSearchFilterState,
  fromSearch.getSearchFilter
);

/**
 * NotificationCountState Selector
 */
export const getNotificationCountState = createFeatureSelector<
  fromNotificationCount.State
>('notificationCount');

export const getgetNotificationCount = createSelector(
  getNotificationCountState,
  fromNotificationCount.getNotificationCount
);
