import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import loadImage from 'blueimp-load-image';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
  public currentPreviewImage: any;
  @Input() thumbImages: any[];
  @Input() currentImageIndex: number
  @Output() changeThumbImages = new EventEmitter<[any, number]>();

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.changeThumbImage();
  }

  onClickPrevious() {
    this.currentImageIndex--;
    this.changeThumbImages.emit([this.thumbImages, this.currentImageIndex]);
    this.changeThumbImage();
  }

  onClickNext() {
    this.currentImageIndex++;
    this.changeThumbImages.emit([this.thumbImages, this.currentImageIndex]);
    this.changeThumbImage();
  }

  isEnabledNext(): boolean {
    return this.thumbImages.length - 1 > this.currentImageIndex ? true : false;
  }

  isEnabledPrevious(): boolean {
    return this.currentImageIndex !== 0 ? true : false;
  }

  clearSelectedImg() {
    this.thumbImages.splice(this.currentImageIndex, 1);
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    }
    this.changeThumbImage();
    this.changeThumbImages.emit([this.thumbImages, this.currentImageIndex]);
  }

  changeThumbImage() {
    if (this.thumbImages.length === 0) {
      return;
    }
    const file = this.thumbImages[this.currentImageIndex];
    loadImage.parseMetaData(file, data => {
      const options = {
        orientation: null,
        canvas: true,
      };
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }

      this.getDataUrl(file, options).then(imageUrl => {
        this.currentPreviewImage = this.sanitizer.bypassSecurityTrustStyle(
          `url(${imageUrl})`
        );
      });
    });
  }

  getDataUrl(blobImage: Blob, options: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      loadImage(
        blobImage,
        canvas => {
          if (canvas instanceof HTMLCanvasElement) {
            resolve(canvas.toDataURL(blobImage.type));
          } else {
            reject('Failed to load image');
          }
        },
        options
      );
    });
  }
}
