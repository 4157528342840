import {
  trigger,
  state,
  animate,
  transition,
  style,
  query,
  group,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.3s', style({ opacity: 1 })),
  ]),
]);

export const panelOpenTrigger = trigger('panelOpenTrigger', [
  state('void', style({ opacity: 0.4 })),
  state('*', style({ opacity: 1 })),
  transition('void => *', animate('0.2s ease-in')),
  transition('* => void', animate('0.1s ease-out')),
]);

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    // query(':enter, :leave', style({ position: 'fixed', width: '100% - 250px' }), {
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
        { optional: true }
      ),
      query(
        ':leave',
        [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
        { optional: true }
      ),
    ]),
  ]),
]);
