import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

import { PaintImageDialogComponent } from './../../../shared/components/paint-image-dialog/paint-image-dialog.component';
import { environment } from './../../../../environments/environment';
import { TenantUtil } from '../../util/tenant-util';

@Component({
  selector: 'app-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.scss'],
})
export class ImageSelectComponent implements OnDestroy {
  @Input()
  public images: File[];
  @Input()
  public isSubmitted: boolean;
  @Input()
  public isImageEditing: boolean;
  @Output()
  public imagesChange = new EventEmitter<any>();
  @Output()
  public isImageEditingChange = new EventEmitter<boolean>();

  // 1MB -> 1000000(1048576)Byte
  public maxImageUploadMB = environment.maxImageUploadMB;

  private currentImageIndex: number;
  public dropZoneOber: boolean;

  private subscription: Subscription;
  private isExtraSmall: Observable<
    BreakpointState
  > = this.breakpointObserver.observe(Breakpoints.XSmall);

  public readonly control = new FileUploadControl(
    { listVisible: false, accept: ['image/*'], discardInvalid: true, multiple: true },
    [FileUploadValidators.accept(['image/*']), FileUploadValidators.fileSize(this.maxImageUploadMB * 1048576)]
  );

  constructor(
    private tenantUtil: TenantUtil,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    // ダミーのURLで初期化
    this.currentImageIndex = 0;
    this.dropZoneOber = false;
    this.isImageEditing = false;
    this.subscription = new Subscription();
  }
  ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe((values: Array<File>) => {
      this.onFileDrop(values);
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * 画像がDrop領域にある
   *
   * @param {any} event
   * @memberof AlbumNewComponent
   */
  onDropzoneOver(event: any) {
    this.dropZoneOber = event;
  }

  /**
   * 画像がドロップされた
   *
   * @param {any} files
   * @memberof AlbumNewComponent
   */
  onFileDrop(files: any) {
    const filesLength = files.length;
    const newFiles = [];
    for (let i = 0; i < filesLength; i++) {
      if (this.isFilterImg(files[i]) && !this.images.includes(files[i])) {
        newFiles.push(files[i]);
      }
    }
    if (newFiles.length > 0) {
      Array.prototype.push.apply(this.images, newFiles);
    }
    if (files.length > 0) {
      this.control.clear();
    }
  }


  /**
   * アクティブな画像が変わった
   *
   * @param {*} event
   * @memberof AlbumNewComponent
   */
  onChangePreview(event: [any, number]) {
    this.images = event[0];
    this.currentImageIndex = event[1];
  }

  /**
   * inputに新しい画像が追加された
   *
   * @param {*} event
   * @memberof AlbumNewComponent
   */
  handleInputChange(event: any) {
    const files = [].slice.call(event.target.files);
    const filteredFiles = files.filter(file => this.isFilterImg(file));
    if (filteredFiles) {
      Array.prototype.push.apply(this.images, filteredFiles);
    }
  }

  /**
   * 画像の形式がjpg,jpeg,png形式のいずれかどうか
   *
   * @param files
   */
  private isFilterImg(file: any) {
    return (
      file.type.includes('jpeg') ||
      file.type.includes('jpg') ||
      file.type.includes('png')
    );
  }

  /**
   * 画像が選択されているかどうか
   *
   * @returns {boolean}
   * @memberof AlbumNewComponent
   */
  isSelectedImg(): boolean {
    return this.images.length !== 0 ? true : false;
  }

  noImageSelected() {
    return !this.isSelectedImg() && this.isSubmitted
      ? { color: '#f44336' }
      : {};
  }

  getCurrentImage() {
    return this.images[this.currentImageIndex];
  }

  onChangeEdit(edit: any) {
    this.isImageEditing = false;
    this.isImageEditingChange.emit(false);
    if (edit !== null) {
      this.images[this.currentImageIndex] = edit;
    }
  }

  onClickEditImage() {
    this.isImageEditing = true;
    this.isImageEditingChange.emit(true);

    const paintDialogData = {
      data: {
        images: this.images,
        type: 'editUploadImage',
        index: this.currentImageIndex,
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: false,
    };

    const paintDialog = this.dialog.open(
      PaintImageDialogComponent,
      paintDialogData
    );

    const userDeviceSub = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        paintDialog.updateSize('100%', '100%');
      }
    });

    const paintDialogSub = paintDialog.afterClosed().subscribe(editedImage => {
      this.isImageEditing = false;
      this.isImageEditingChange.emit(false);
      if (editedImage) {
        this.images[this.currentImageIndex] = editedImage;
      }
      userDeviceSub.unsubscribe();
    });

    this.subscription.add(paintDialogSub);
  }
}
