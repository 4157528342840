import {
  Component,
  OnDestroy,
  Inject,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Observable ,  Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { environment } from './../../../../environments/environment';

import * as fromRoot from './../../../reducers';
import * as layout from './../../actions/layout.actions';
import * as navigation from './../../actions/navigation.actions';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnDestroy {
  @Input() pageTitle = '';
  @Input() openMobileSearch = false;
  @Input() showSearchForm: boolean;
  @Output() searchEntered = new EventEmitter<string>();
  @Output() openDetailSearchForm = new EventEmitter<boolean>();
  @Output() clickMenu = new EventEmitter<boolean>();
  @Output() clickSearchIconButton = new EventEmitter<boolean>();

  @ViewChild(SearchComponent, { static: true }) public searchComponent: SearchComponent;

  showSidenav$: Observable<boolean>;
  unreadCount$: Observable<number>;
  public appName: string;

  // subscriptions
  private subscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {
    this.subscription = new Subscription();
    this.appName = environment.appName;
    this.document.body.style.overflow = 'auto';

    this.showSidenav$ = this.store.pipe(select(fromRoot.getShowSidenav));
    this.unreadCount$ = this.store.pipe(
      select(fromRoot.getgetNotificationCount)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeSidenav() {
    if (this.openMobileSearch) {
      this.openMobileSearch = false;
    }
    this.store.dispatch(new layout.CloseSidenav());
  }

  openSidenav() {
    if (this.openMobileSearch) {
      this.openMobileSearch = false;
    }
    this.store.dispatch(new layout.OpenSidenav());
  }

  onClickLogo() {
    this.store.dispatch(new navigation.Go({ path: [`/`] }));
  }

  /**
   * 検索ボタンをクリックした
   *
   * @param {*} event
   * @memberof ToolbarComponent
   */
  onClickSearchButton(event: any) {
    this.openMobileSearch = !this.openMobileSearch ? true : false;
    this.clickSearchIconButton.emit(this.openMobileSearch);
  }

  /**
   * メニュー項目をクリックした
   *
   * @param {string} itemName
   * @memberof SecureLayoutComponent
   */
  onClickMenuItem(itemName: string) {
    this.store.dispatch(new navigation.Go({ path: [`${itemName}`] }));
  }

  closeSearchDetail() {
    this.searchComponent.onUpdownBtnClick();
  }

  /**
   * 検索フォームの開閉の状態変更
   */
  changeSearchFormState() {
    this.showSearchForm ? this.closeSearchForm() : this.openSearchForm();
  }

  openSearchForm() {
    this.store.dispatch(new layout.OpenSearchForm());
  }

  closeSearchForm() {
    this.store.dispatch(new layout.CloseSearchForm());
  }
}
