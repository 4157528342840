import { Action } from '@ngrx/store';

import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { User, UserSession } from '../../models';
import { environment } from './../../../environments/environment';

export interface State {
  user: User;
  session: UserSession;
  authority: {
    isAdmin: boolean;
    isSv: boolean;
  };
  mediaSignature: string;
  error: null;
}

export const initialState: State = {
  user: null,
  session: {
    jwtToken: null,
    refreshToken: null,
  },
  authority: {
    isAdmin: false,
    isSv: false,
  },
  mediaSignature: null,
  error: null,
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.GetUserComplete: {
      return {
        ...state,
        user: action.payload,
        authority: {
          isAdmin:
            action.payload.groups.indexOf(environment.groups.adminGroup.key) >=
            0
              ? true
              : false,
          isSv:
            action.payload.groups.indexOf(environment.groups.svGroup.key) >= 0
              ? true
              : false,
        },
      };
    }

    case AuthActionTypes.GetSignedUrlComplete: {
      return {
        ...state,
        mediaSignature: action.payload,
      };
    }

    case AuthActionTypes.SetUserSession: {
      return {
        ...state,
        session: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;
export const getIsAdmin = (state: State) => state.authority.isAdmin;
export const getIsSv = (state: State) => state.authority.isSv;
export const getMediaSignature = (state: State) => state.mediaSignature;
export const getJwt = (state: State) => state.session.jwtToken;
