import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Canvas, FabricImage, ImageFormat, PencilBrush, Shadow } from 'fabric';
import loadImage from 'blueimp-load-image';
import * as fabric from 'fabric';

export class DrawingBrushState {
  color: string;
  width: number;
  shadowBlur: number;
}

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss'],
})
export class ImageEditComponent implements OnInit {
  @Input() editImage: File;
  @Output() changeEditImage = new EventEmitter<any>();

  /**
   * imageUrl, imageName, imageTypeが存在するのは、
   * アップロードされた画像をコメント欄で編集するとき
   */
  @Input() imageUrl: string;
  @Input() imageName: string;
  @Input() imageType: string;

  public localEditImage: string;
  public resultImage: File;
  private canvas: Canvas;
  private isRedoing: boolean;
  private canvasHistory: any[];
  private drawingBrushState: DrawingBrushState;
  get drawingMode(): boolean {
    return this.canvas.isDrawingMode;
  }
  set drawingMode(val: boolean) {
    this.canvas.isDrawingMode = val;
  }
  // controlの幅は、canvasに合わせる
  public controlWidth: number;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
    this.isRedoing = false;
    this.canvasHistory = [];
    this.resultImage = null;

    this.drawingBrushState = new DrawingBrushState();
    this.drawingBrushState.color = '#000000';
    this.drawingBrushState.width = 5;
    this.drawingBrushState.shadowBlur = 0;
  }

  ngOnInit() {
    // canvasオブジェクトの作成
    this.canvas = 
     new Canvas('canvas', {
      isDrawingMode: true,
      selection: true,
      stateful: true,
    });
    // canvasオブジェクトにブラシをセットアップ
    this.setupDrawingBrush();

    if (!this.imageUrl) {
      this.imageName = this.editImage.name;
      this.imageType = this.editImage.type;
      this.putFileToUrl();
    }
  }

  setupDrawingBrush() {
    this.canvas.freeDrawingBrush = new PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.color = this.drawingBrushState.color;
    this.canvas.freeDrawingBrush.width = this.drawingBrushState.width;
    this.canvas.freeDrawingBrush.shadow =  new Shadow({
      color: this.drawingBrushState.color,
      blur: this.drawingBrushState.shadowBlur,
      offsetX: 0,
      offsetY: 0
    });
    this.canvas.hoverCursor = 'move';
  }

  putFileToUrl() {
    const file = this.editImage;
    loadImage.parseMetaData(file, data => {
      const options = {
        orientation: null,
        canvas: true,
      };
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }

      this.getDataUrl(file, options)
        .then(result => {
          this.imageUrl = result;
        });
    });
  }

  getDataUrl(blobImage: Blob, options: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      loadImage(
        blobImage,
        canvas => {
          if (canvas instanceof HTMLCanvasElement) {
            resolve(canvas.toDataURL(blobImage.type));
          } else {
            reject(new Error("The result is not a canvas element"));
          }
        },
        options
      );
    });
  }

  // setCanvas()が呼ばれるのは、HTMLでイメージサイズ取得用の画像のロードが終わったとき
  setCanvas() {
    // サイズ取得用画像のCSSが適用されるのにラグがあるため100ms遅延
    setTimeout(() => {
      FabricImage.fromURL(this.imageUrl, { crossOrigin: 'anonymous' })
      .then(image => {
        this.canvas.backgroundImage = image;
        this.setCanvasSize();
        this.canvas.renderAll();
      });

      // 新しいオブジェクトが追加されたかを監視
      this.canvas.on('object:added', e => {
        if (!this.isRedoing) {
          this.canvasHistory = [];
        }
        this.isRedoing = false;
      });
    }, 100);
  }

  setCanvasSize() {
    const windowHeight: number = window.innerHeight || document.documentElement.clientHeight || 0;
    const scaleDownRate = 0.8;
    const maxHeight: number = windowHeight * scaleDownRate;
    const ua = navigator.userAgent;
    const orientation = window.orientation;

    const image = document.getElementById('shadow-image');
    let imageWidth = image.offsetWidth;
    let imageHeight = image.offsetHeight;

    // 最大の高さ以上 or iPadが横向きだったら縮小
    if (imageHeight > maxHeight || (ua.indexOf('iPad') >= 0 && orientation !== 0)) {
      const scale = maxHeight / imageHeight; // 画像サイズが大きすぎるときに縮小
      imageWidth = imageWidth * scale;
      imageHeight = maxHeight;
    }

    this.controlWidth = imageWidth;
    this.canvas.setWidth(imageWidth);
    this.canvas.setHeight(imageHeight);

    this.canvas.backgroundImage.scaleToWidth(imageWidth);
    this.canvas.backgroundImage.scaleToHeight(imageHeight);
  }

  onCancelEditing() {
    this.changeEditImage.emit(null);
  }

  onFinishEditing() {
    this.saveImage();
    this.changeEditImage.emit(this.resultImage);
  }

  onChangeDrawingColor(color: string) {
    this.drawingBrushState.color = color || '#000000';
    this.canvas.freeDrawingBrush.color = this.drawingBrushState.color;
  }

  onChangeDrawingWidth(width: number) {
    this.drawingBrushState.width = width || 5;
    this.canvas.freeDrawingBrush.width = this.drawingBrushState.width;
  }

  saveImage() {
    const imageFormat = this.imageType.split('/')[1] as ImageFormat;
    if (['jpeg', 'png', 'webp'].includes(imageFormat)) {
    const dataUrl = this.canvas.toDataURL({ format: imageFormat , multiplier:1});
    this.resultImage = this.dataURLtoFile(dataUrl, this.imageName);
    }
  }

  undo() {
    if (this.canvas._objects.length > 0) {
      const undoObject = this.canvas._objects.pop();
      this.canvasHistory.push(undoObject);
      this.canvas.renderAll();
    }
  }

  redo() {
    if (this.canvasHistory.length > 0) {
      this.isRedoing = true;
      const redoObject = this.canvasHistory.pop();
      this.canvas.add(redoObject);
    }
  }

  deleteObject() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.remove(activeObject);
    }
  }

  getBruchColor() {
    return this.canvas.freeDrawingBrush.color;
  }

  dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1] as string,
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime }) as File;
  }
}
