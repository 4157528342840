import { Action } from '@ngrx/store';

import { User, UserSession } from './../../models';

export enum AuthActionTypes {
  // Cognitoユーザー情報取得
  GetUser = '[Auth] Get User',
  GetUserComplete = '[Auth] Get User Complete',
  GetUserError = '[Auth] Get User Error',
  // 署名付きURLの発行
  GetSignedUrl = '[Auth] Get SignedUrl',
  GetSignedUrlComplete = '[Auth] Get SignedUrl Complete',
  GetSignedUrlError = '[Auth] Get SignedUrl Error',
  // AWS grobal credentialの取得
  SetUserSession = '[Auth] Set UserSession',
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;
}

export class GetUserComplete implements Action {
  readonly type = AuthActionTypes.GetUserComplete;

  constructor(public payload: User) {}
}

export class GetUserError implements Action {
  readonly type = AuthActionTypes.GetUserError;

  constructor(public payload: string) {}
}

export class GetSignedUrl implements Action {
  readonly type = AuthActionTypes.GetSignedUrl;
}

export class GetSignedUrlComplete implements Action {
  readonly type = AuthActionTypes.GetSignedUrlComplete;

  constructor(public payload: string) {}
}

export class GetSignedUrlError implements Action {
  readonly type = AuthActionTypes.GetSignedUrlError;

  constructor(public payload: string) {}
}

export class SetUserSession implements Action {
  readonly type = AuthActionTypes.SetUserSession;

  constructor(public payload: UserSession) {}
}

export type AuthActions =
  | GetUser
  | GetUserComplete
  | GetUserComplete
  | GetSignedUrl
  | GetSignedUrlComplete
  | GetSignedUrlError
  | SetUserSession;
