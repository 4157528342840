<div class="mat-toolbar-wapper">
  <mat-toolbar color="primary">
    <!-- Sidenavが開いている時 -->
    <button mat-icon-button *ngIf="showSidenav$ | async" (click)="closeSidenav()">
      <mat-icon class="icon">menu</mat-icon>
    </button>
    <!-- Sidenavが閉じている時 -->
    <button mat-icon-button *ngIf="!(showSidenav$ | async)" (click)="openSidenav()">
      <mat-icon class="icon">menu</mat-icon>
    </button>
    <img class="main-logo" fxHide.lt-sm src="assets/postfor_logo.svg" alt="postfor" width="90"(click)="onClickLogo()">
    <mat-menu #userMenu="matMenu" color="primary">
      <a mat-menu-item (click)="onClickMenuItem('/userinfo')">ユーザー情報</a>
      <a mat-menu-item (click)="onClickMenuItem('/logout')">ログアウト</a>
    </mat-menu>

    <div class="page-title-mobile" fxHide.gt-xs fxLayout="row" fxLayoutAlign="center">
      <img class="page-title-mobile__logo" src="assets/postfor_logo.svg" alt="postfor" width="50">
      <p class="page-title-mobile__text">{{ pageTitle | slice: 7 }}</p>
    </div>

    <app-search
      fxHide.lt-sm
      (changeSearchFormState)="changeSearchFormState()"
      [openDetailSearch]="showSearchForm"
      >
    </app-search>
    <div class="remaining-space"></div>

    <button class="notification-button" mat-icon-button fxHide.lt-sm (click)="onClickMenuItem('/notifications')">
      <mat-icon matTooltip="お知らせ" class="icon">notifications</mat-icon>
      <span class="notification-button__label" *ngIf="(unreadCount$ | async) > 0">{{ unreadCount$ | async }}</span>
    </button>
    <button mat-icon-button fxHide.lt-sm [matMenuTriggerFor]="userMenu">
      <mat-icon matTooltip="アカウント" class="icon">account_circle</mat-icon>
    </button>
    <button mat-icon-button fxHide.gt-xs *ngIf="!openMobileSearch; else mobileSearchCloseBtn" (click)="onClickSearchButton($event)">
      <mat-icon class="icon">search</mat-icon>
    </button>
    <ng-template #mobileSearchCloseBtn>
      <button mat-icon-button fxHide.gt-xs (click)="onClickSearchButton($event)">
        <mat-icon class="icon">close</mat-icon>
      </button>
    </ng-template>
  </mat-toolbar>
</div>
