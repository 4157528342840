import { Action } from '@ngrx/store';

import { Shop, Event, Category, ShopGroup } from './../../models';

export enum MasterActionTypes {
  LoadShops = '[Master] Load Shops',
  LoadShopsComplete = '[Master] Load Shops Complete',
  LoadShopsError = '[Master] Load Shops Error',
  LoadEvents = '[Master] Load Events',
  LoadEventsComplete = '[Master] Load Events Complete',
  LoadEventsError = '[Master] Load Events Error',
  LoadCategories = '[Master] Load Categories',
  LoadCategoriesComplete = '[Master] Load Categories Complete',
  LoadCategoriesError = '[Master] Load Categories Error',
  LoadActualShops = '[Master] Load ActualShops',
  LoadActualShopsComplete = '[Master] Load ActualShops Complete',
  LoadActualShopsError = '[Master] Load ActualShops Error',
  LoadActualEvents = '[Master] Load ActualEvents',
  LoadActualEventsComplete = '[Master] Load ActualEvents Complete',
  LoadActualEventsError = '[Master] Load ActualEvents Error',
  LoadActualCategories = '[Master] Load ActualCategories',
  LoadActualCategoriesComplete = '[Master] Load ActualCategories Complete',
  LoadActualCategoriesError = '[Master] Load ActualCategories Error',
  LoadOtherError = '[Master] Load Other Error',
}

export class LoadShops implements Action {
  readonly type = MasterActionTypes.LoadShops;
}

export class LoadShopsComplete implements Action {
  readonly type = MasterActionTypes.LoadShopsComplete;

  constructor(public payload: Shop[]) {}
}

export class LoadShopsError implements Action {
  readonly type = MasterActionTypes.LoadShopsError;

  constructor(public payload: any) {}
}

export class LoadEvents implements Action {
  readonly type = MasterActionTypes.LoadEvents;
}

export class LoadEventsComplete implements Action {
  readonly type = MasterActionTypes.LoadEventsComplete;

  constructor(public payload: Event[]) {}
}

export class LoadEventsError implements Action {
  readonly type = MasterActionTypes.LoadEventsError;

  constructor(public payload: any) {}
}

export class LoadCategories implements Action {
  readonly type = MasterActionTypes.LoadCategories;
}

export class LoadCategoriesComplete implements Action {
  readonly type = MasterActionTypes.LoadCategoriesComplete;

  constructor(public payload: Category[]) {}
}

export class LoadCategoriesError implements Action {
  readonly type = MasterActionTypes.LoadCategoriesError;

  constructor(public payload: any) {}
}

export class LoadActualShops implements Action {
  readonly type = MasterActionTypes.LoadActualShops;
}

export class LoadActualShopsComplete implements Action {
  readonly type = MasterActionTypes.LoadActualShopsComplete;

  constructor(public payload: Shop[]) {}
}

export class LoadActualShopsError implements Action {
  readonly type = MasterActionTypes.LoadActualShopsError;

  constructor(public payload: any) {}
}

export class LoadActualEvents implements Action {
  readonly type = MasterActionTypes.LoadActualEvents;
}

export class LoadActualEventsComplete implements Action {
  readonly type = MasterActionTypes.LoadActualEventsComplete;

  constructor(public payload: Event[]) {}
}

export class LoadActualEventsError implements Action {
  readonly type = MasterActionTypes.LoadActualEventsError;

  constructor(public payload: any) {}
}

export class LoadActualCategories implements Action {
  readonly type = MasterActionTypes.LoadActualCategories;
}

export class LoadActualCategoriesComplete implements Action {
  readonly type = MasterActionTypes.LoadActualCategoriesComplete;

  constructor(public payload: Category[]) {}
}

export class LoadActualCategoriesError implements Action {
  readonly type = MasterActionTypes.LoadActualCategoriesError;

  constructor(public payload: any) {}
}
export class LoadOtherError implements Action {
  readonly type = MasterActionTypes.LoadOtherError;

  constructor(public payload: any) {}
}

export type MasterActions =
  | LoadShops
  | LoadShopsComplete
  | LoadShopsError
  | LoadEvents
  | LoadEventsComplete
  | LoadEventsError
  | LoadCategories
  | LoadCategoriesComplete
  | LoadCategoriesError
  | LoadActualShops
  | LoadActualShopsComplete
  | LoadActualShopsError
  | LoadActualEvents
  | LoadActualEventsComplete
  | LoadActualEventsError
  | LoadActualCategories
  | LoadActualCategoriesComplete
  | LoadActualCategoriesError;
