import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './../shared/shared.module';
import { MaterialModule } from './../material/material.module';

import { AuthGuard, AuthedGuard, AdminGuard } from './guards';

import { AppComponent } from './containers/app.component';
import { SecureLayoutComponent, AuthLayoutComponent } from './layouts';
import { NotFoundComponent } from './containers/not-found/not-found.component';
import { AboutComponent } from './containers/about/about.component';
import { LogoutComponent } from './containers/logout/logout.component';

import { LoadingComponent } from './components/loading/loading.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SearchComponent } from './components/search/search.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { SidenavFooterComponent } from './components/sidenav-footer/sidenav-footer.component';

export const COMPONENTS = [
  AppComponent,
  SecureLayoutComponent,
  AuthLayoutComponent,
  NotFoundComponent,
  AboutComponent,
  LogoutComponent,
  LoadingComponent,
  ToolbarComponent,
  SearchComponent,
  SearchFormComponent,
  SidenavFooterComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: [AuthGuard, AuthedGuard, AdminGuard]
    };
}
}
