import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromRoot from './..//reducers';
import { User } from './../models';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotiferService implements OnDestroy {
  user$: Observable<User>;
  userInfo: User;
  private subscription: Subscription;

  constructor(
    private apiService: ApiService,
    private store: Store<fromRoot.State>
  ) {
    this.subscription = new Subscription();
    this.user$ = this.store.pipe(select(fromRoot.getUser));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * フロントエンドで起きたエラーをサーバーに送る
   * @param error
   */
  public postFrontError(errorObj) {
    console.error(errorObj);
    const error = errorObj.stack;
    const ua: string = window.navigator.userAgent;
    const href: string = window.location.href;
    const width: number = window.innerWidth;
    const scroll: number = window.pageYOffset;
    const clientInfo = {
      ua,
      href,
      width,
      scroll,
      error
    };

    const userSub = this.user$.subscribe(
      result => {
        this.userInfo = {
          name: result.name,
          sub: result.sub,
          email: result.email,
          shop_id: result.shop_id,
          groups: result.groups
        };
      }
    );
    this.subscription.add(userSub);

    const payload = Object.assign({}, clientInfo, this.userInfo);

    this.apiService
      .post('/error/front-error', payload)
      .pipe(take(1))
      .subscribe();
  }

  /**
   * 通信エラーをサーバーに送る
   * @param error
   */
  public postTransferError(errorObj) {
    console.error(errorObj);
    const error: string = errorObj.stack;
    const ua: string = window.navigator.userAgent;
    const href: string = window.location.href;
    const width: number = window.innerWidth;
    const scroll: number = window.pageYOffset;
    const clientInfo = {
      ua,
      href,
      width,
      scroll,
      error
    };

    const userSub = this.user$.subscribe(
      result => {
        this.userInfo = {
          name: result.name,
          sub: result.sub,
          email: result.email,
          shop_id: result.shop_id,
          groups: result.groups
        };
      }
    );
    this.subscription.add(userSub);

    const payload = Object.assign({}, clientInfo, this.userInfo);

    this.apiService
      .post('/error/transfer-error', payload)
      .pipe(take(1))
      .subscribe();
  }
}
