import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

const SEARCH_WORDS_KEY = environment.local_searchQueryParams;
const SEARCH_WORDS_LIMIT = environment.historyCount;

interface SerchWord {
  word: string;
  timestamp: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchHistoryService {
  /**
   * Creates an instance of SearchHistoryService.
   * @memberof SearchHistoryService
   */
  constructor() {
    const items = localStorage.getItem(SEARCH_WORDS_KEY);
    if (items === null) {
      localStorage.setItem(SEARCH_WORDS_KEY, '');
    }
  }

  /**
   * 全件取得
   *
   * @returns {SerchWord[]}
   * @memberof SearchHistoryService
   */
  public getItems(): SerchWord[] {
    const items = localStorage.getItem(SEARCH_WORDS_KEY);
    return items !== 'undefined' && items !== '' ? JSON.parse(items) : [];
  }

  /**
   * 保存(FIFO)
   *
   * @param {string} word
   * @memberof SearchHistoryService
   */
  public pushItem(word: string) {
    const items = this.getItems().filter(x => x.word !== word);
    const item: SerchWord = { word: word, timestamp: Date.now().toString() };
    if (items.length >= SEARCH_WORDS_LIMIT) {
      items.pop();
    }
    items.unshift(item);
    localStorage.setItem(SEARCH_WORDS_KEY, JSON.stringify(items));
  }

  /**
   * 全件削除
   *
   * @memberof SearchHistoryService
   */
  public clearAll() {
    localStorage.clear();
  }

  /**
   * 指定keyのアイテムを削除
   *
   * @param {string} key
   * @memberof SearchHistoryService
   */
  public clearItems(key: string) {
    localStorage.remove(key);
  }

  /**
   * 指定key,valueのアイテムを削除
   *
   * @param {string} value
   * @memberof SearchHistoryService
   */
  public clearItemsValue(value: string) {
    const items = this.getItems();
    const filterdItem = items.filter(x => x.word !== value);
    localStorage.setItem(SEARCH_WORDS_KEY, JSON.stringify(filterdItem));
  }
}
