import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ApiService } from './../../services/api.service';
import { Notification, NotificationQueryParams } from './../../models';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  /**
   * Creates an instance of NotificationsService.
   * @param {ApiService} apiService
   * @memberof NotificationsService
   */
  constructor(private apiService: ApiService) {}

  /**
   * 通知の取得
   *
   * @param {NotificationQueryParams} queryParams
   * @returns {Observable<Notification>}
   * @memberof NotificationsService
   */
  fetch(queryParams: NotificationQueryParams): Observable<Notification> {
    let params: string;
    params = `?per_page=${queryParams.per_page}&page=${queryParams.page}`;
    return this.apiService.get<any>(
      `/notifications${params}`,
      new HttpParams()
    );
  }

  /**
   * 通知件数の取得
   *
   * @returns {Observable<number>}
   * @memberof NotificationsService
   */
  fetchUnreadCount(): Observable<number> {
    return this.apiService
      .get<any>('/notifications?count', new HttpParams())
      .pipe(map(data => data.unread_count));
  }

  /**
   * 指定した時刻までの通知を既読にする
   *
   * @param {Date} [time=new Date()]
   * @returns {(Observable<boolean | string>)}
   * @memberof NotificationsService
   */
  markRead(time: Date = new Date()): Observable<boolean | string> {
    const params = new HttpParams();
    const timestamp = time.toISOString().replace('.000', '');

    params.set('last_read_at', timestamp);
    return this.apiService
      .put('/notifications', params)
      .pipe(map(result => true));
  }
}
