import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './app-routes';

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 56],
    useHash: false
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  static forRoot(): ModuleWithProviders<AppRoutingModule> {
    return {
        ngModule: AppRoutingModule,
    };
}
}
