import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from '../../../models';
import { ImageUrlService } from '../../../services';
import { TenantUtil } from '../../util/tenant-util';
import { Subscription ,  Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromPost from '../../../posts/reducers';

@Component({
  selector: 'app-paint-image-dialog',
  templateUrl: './paint-image-dialog.component.html',
  styleUrls: ['./paint-image-dialog.component.scss']
})
export class PaintImageDialogComponent implements OnInit, OnDestroy   {
  public mediaList: Media[];
  private mediaEndpoint: string;
  private mediaSignature: string;
  public imageUrl: string;
  public imageName: string;
  public imageType: string;
  private commentImageIndex$: Observable<number>;
  public editType: string;
  public imageFile: Media;

  private subscription: Subscription;

  constructor(
    public matDialogRef: MatDialogRef<PaintImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageUrlService: ImageUrlService,
    private tenantUtil: TenantUtil,
    private store: Store<fromPost.State>
  ) {
    this.editType = this.data.type;
    this.mediaList = this.data.images;
    this.mediaEndpoint = this.tenantUtil.mediaEndpoint;
    this.subscription = new Subscription();
    this.commentImageIndex$ = this.store.pipe(select(fromPost.getCurrentImageIndex));
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.editType === 'editCommentExistingImage') {
        const imageUrlServiceSub = this.imageUrlService
          .getSignedUrl()
          .subscribe(url => {
            this.mediaSignature = url;
            // 署名取得完了後にURLを取得
            this.getImageUrl();
          });
        this.subscription.add(imageUrlServiceSub);
      } else if (this.editType === 'editCommentNewImage') {
        this.imageFile = this.mediaList[0];
      } else if (this.editType === 'editUploadImage') {
        const index = this.data.index;
        this.imageFile = this.mediaList[index];
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * 画像のURLを取得
   *
   * @memberOf PaintCommentImageComponent
   */
  getImageUrl() {
    const imageIndexSub = this.commentImageIndex$.subscribe(
      index => {
        const thumbImage = this.mediaList[index];
        this.imageName = thumbImage.id;
        this.imageType = thumbImage.ext;
        this.imageUrl = `${this.mediaEndpoint}M/${thumbImage.id}.${thumbImage.ext}?${this.mediaSignature}&ts=${Date.now()}`;
      }
    );
    this.subscription.add(imageIndexSub);
  }

  onChangeEdit(edit: string) {
    this.matDialogRef.close(edit);
  }
}
