import { Inject, Injectable } from '@angular/core';
import { catchError ,  map, switchMap, tap, take } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable ,  Observer ,  from as fromPromise, zip } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import {
  ListUsersResponse,
  ListUsersInGroupResponse,
  UsersListType,
  AdminUserGlobalSignOutResponse,
  AdminUpdateUserAttributesResponse
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { CognitoIdentityProvider } from 'aws-sdk/clients/cognitoidentity';
import { CognitoIdentityServiceProvider as AwsCognitoIdentityServiceProvider } from 'aws-sdk/clients/all';
import { CognitoIdentityCredentials } from 'aws-sdk/lib/credentials/cognito_identity_credentials';
import { LoginsMap } from 'aws-sdk/clients/cognitoidentity';
import { jwtDecode } from 'jwt-decode';

import { environment } from '../../environments/environment';
import { User } from '../models';

import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';
import { VolumeId } from 'aws-sdk/clients/storagegateway';
import { TenantUtil } from '../shared/util/tenant-util';
import * as fromRoot from '../reducers';
import * as auth from '../core/actions/auth.actions';

/**
 * CognitoUserPoolに関する処理
 *
 * @export
 * @class CognitoUtil
 */
@Injectable({
  providedIn: 'root'
})
export class CognitoUtil {
  public static _REGION = environment.region;
  public cognitoCreds: CognitoIdentityCredentials;
  private timeoutMs = environment.timeoutMs;
  jwt$: Observable<string>;

  /**
   * Creates an instance of ApiService.
   * @param {HttpClient} http
   * @param {TenantUtil} tenantUtil
   */
  constructor(
    @Inject(AuthService) private authService: AuthService,
    private http: HttpClient,
    private tenantUtil: TenantUtil,
    private store: Store<fromRoot.State>,
  ) {
    AWS.config.region = CognitoUtil._REGION;
    this.jwt$ = this.store.pipe(select(fromRoot.getJwt));
  }

  /**
   * CognitoUserPoolの取得
   *
   * @returns {Observable<CognitoUserPool>}
   * @memberof CognitoUtil
   */
  public getUserPool(): Observable<CognitoUserPool> {
    return this.authService.getAuthInfo().pipe(
      map(
        authInfo =>
          new CognitoUserPool({
            UserPoolId: authInfo.user_pool_id,
            ClientId: authInfo.client_id
          })
      )
    );
  }

  /**
   * 現在のユーザーを取得
   *
   * @returns
   * @memberof CognitoUtil
   */
  public getCurrentUser(): Observable<CognitoUser> {
    return this.getUserPool().pipe(map(userPool => userPool.getCurrentUser()));
  }

  /**
   *
   * @param {CognitoIdentityCredentials} creds
   * @memberof CognitoUtil
   */
  public setCognitoCreds(creds: CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  /**
   *
   * @returns {CognitoIdentityCredentials}
   * @memberof CognitoUtil
   */
  public getCognitoCreds(): CognitoIdentityCredentials {
    return this.cognitoCreds;
  }

  /**
   * 強制的にユーザーをログアウトさせる
   * コールしているAPIはAdmin権限が必要
   *
   * @public
   * @param {string} username
   * @returns {Promise<AdminUserGlobalSignOutResponse>}
   * @memberof CognitoUtil
   */
  public forcedSingOutUser(
    username: string
  ): Promise<AdminUserGlobalSignOutResponse> {
    return this.authService
      .getAuthInfo()
      .pipe(
        map(authInfo => {
          const params = {
            UserPoolId: authInfo.user_pool_id,
            Username: username
          };
          return params;
        }),
        switchMap(params => {
          const cognitoISP = new AwsCognitoIdentityServiceProvider();
          return cognitoISP.adminUserGlobalSignOut(params).promise();
        })
      )
      .toPromise();
  }

  /**
   * credentialsを作成
   *
   * @param {string} idTokenJwt
   * @returns {Observable<CognitoIdentityCredentials>}
   * @memberof CognitoUtil
   */
  public buildCognitoCreds(
    idTokenJwt: string,
    loginId?: string
  ): Observable<CognitoIdentityCredentials> {
    return this.authService.getAuthInfo().pipe(
      map(authInfo => {
        const url =
          'cognito-idp.' +
          CognitoUtil._REGION.toLowerCase() +
          '.amazonaws.com/' +
          authInfo.user_pool_id;

        const logins: LoginsMap = {};
        logins[url] = idTokenJwt;

        const params = {
          IdentityPoolId: authInfo.identity_pool_id,
          LoginnId: loginId ? loginId : undefined,
          Logins: logins
        };

        return new AWS.CognitoIdentityCredentials(params);
      }),
      tap(creds => {
        this.setCognitoCreds(creds);
      })
    );
  }

  /**
   * IDトークンの取得
   *
   * @returns {Observable<string>}
   * @memberof CognitoUtil
   */
  public getIdToken(): Observable<string | any> {
    return this.getCurrentUser().pipe(
      switchMap(user =>
        Observable.create((observer: Observer<any>) => {
          if (this.getCurrentUser() !== null) {
            user.getSession((error, session) => {
              if (error) {
                observer.error(error);
              } else {
                if (session.isValid()) {
                  observer.next(session.getIdToken().getJwtToken());
                } else {
                  user.refreshSession(
                    session.getRefreshToken(),
                    (refreshError, refreshedSession) => {
                      if (!refreshError) {
                        observer.next(
                          refreshedSession.getIdToken().getJwtToken()
                        );
                      } else {
                        observer.error(refreshError);
                      }
                    }
                  );
                }
              }
            });
          } else {
            observer.error(null);
          }
        })
      )
    );
  }

  /**
   * Sessionの取得
   *
   * @returns {Observable<string>}
   * @memberof CognitoUtil
   */
  public getSession(): Observable<any> {
    return this.getCurrentUser().pipe(
      switchMap(user =>
        Observable.create((observer: Observer<any>) => {
          if (this.getCurrentUser() !== null) {
            user.getSession((error, session) => {
              if (error) {
                observer.error(error);
              } else {
                observer.next(session);
              }
            });
          } else {
            observer.error(null);
          }
        })
      )
    );
  }

  /**
   * JWTをパースして、Userオブジェクトを生成する
   *
   * @param {string} jwt
   * @returns {User}
   * @memberof CognitoUtil
   */
  public parseToUser(fromJwt: string): User {
    const decoded = jwtDecode(fromJwt);
    return new User({
      sub: decoded['sub'],
      name: decoded['name'],
      shop_id: decoded['family_name'],
      email: decoded['email'],
      groups: decoded['cognito:groups'] || []
    });
  }

  /**
   * jwtが更新されていた場合、storeに再設定する
   * @param result
   */
  public setUserSession(result: Array<any>) {
    if (result[0] !== result[1].getIdToken().getJwtToken()) {
      this.store.dispatch(
        new auth.SetUserSession({
          jwtToken: result[1].getIdToken().getJwtToken(),
          refreshToken: result[1].getRefreshToken().getToken()
        })
      );
    }
  }

}
