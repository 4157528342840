import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CognitoUtil } from './cognito.service';
import { User } from './../models/user.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserParametersService {
  private user: User = null;

  /**
   * Creates an instance of UserParametersService.
   * @param {CognitoUtil} cognitoUtil
   * @memberof UserParametersService
   */
  constructor(public cognitoUtil: CognitoUtil) {}

  /**
   * Cognitoユーザーパラメータの取得
   * (Observable版)
   *
   * @returns {Observable<User>}
   * @memberof UserParametersService
   */
  public getParameters(): Observable<User | any> {
    // キャッシュを使う
    if (this.user !== null && this.user instanceof Object) {
      return of(this.user);
    }
    return this.cognitoUtil.getCurrentUser().pipe(
      switchMap(cognitoUser =>
        Observable.create((observer: Observer<any>) => {
          if (cognitoUser !== null) {
            cognitoUser.getSession((getSessionError, session) => {
              if (getSessionError) {
                observer.error(getSessionError);
              } else {
                cognitoUser.getUserAttributes((getAttributeErr, result) => {
                  if (getAttributeErr) {
                    observer.error(getAttributeErr);
                  } else {
                    this.user = this.cognitoUtil.parseToUser(
                      session.getIdToken().getJwtToken()
                    );
                    observer.next(this.user);
                  }
                });
              }
            });
          } else {
            observer.error(null);
          }
        })
      )
    );
  }

  /**
   * Cognito UserAttributeのgroupからAdmin権限を判定
   *
   * @returns {Observable<boolean>}
   * @memberof UserParametersService
   */
  public isAdmin(): Observable<boolean> {
    return this.getParameters().pipe(
      map(params => {
        return params.groups.includes(environment.groups.adminGroup.key);
      })
    );
  }

  /**
   * ログアウト時にキャッシュしているユーザー情報をクリア
   *
   * @memberof UserParametersService
   */
  public refreshUserInfo() {
    if (this.user) {
      this.user = null;
    }
  }
}
