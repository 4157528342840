import { Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Post } from '../../models/post.model';
import { PostsActions, PostsActionTypes } from '../actions/posts.actions';

export interface State extends EntityState<Post> {
  selectedPostId: string | null;
  isDirectLink: boolean;
  currentImageIndex: number;
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>({
  selectId: (post: Post) => post.post_id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedPostId: null,
  isDirectLink: false,
  currentImageIndex: null,
});

export function reducer(state = initialState, action: PostsActions): State {
  switch (action.type) {
    case PostsActionTypes.SearchComplete: {
      return {
        ...adapter.setAll(action.payload, state),
        selectedPostId: state.selectedPostId,
        isDirectLink: false,
      };
    }

    case PostsActionTypes.SearchNextComplete: {
      return {
        ...adapter.addMany(action.payload, state),
        selectedPostId: state.selectedPostId,
      };
    }

    case PostsActionTypes.FetchPostComplete: {
      // 削除action直後にfetchが走った場合にstateに影響を与えないためのnullチェック
      if (action.payload) {
        return {
          ...adapter.addOne(action.payload, state),
          selectedPostId: action.payload.post_id,
          isDirectLink: true,
        };
      } else {
        return state;
      }
    }

    case PostsActionTypes.CreatePostComplete: {
      return {
        ...adapter.addOne(action.payload, state),
      };
    }

    case PostsActionTypes.CreateMultiplePostComplete: {
      return {
        ...adapter.addMany(action.payload, state),
      };
    }

    case PostsActionTypes.UpdatePostComplete: {
      return {
        ...adapter.updateOne(
          { id: action.payload.post_id, changes: action.payload },
          state
        ),
        selectedPostId: state.selectedPostId,
      };
    }

    case PostsActionTypes.DeletePostComplete: {
      return {
        ...adapter.removeOne(action.payload, state),
        selectedPostId: null,
      };
    }

    case PostsActionTypes.GetPost: {
      return {
        ...state,
        selectedPostId: action.payload,
      };
    }

    case PostsActionTypes.CreateNiceComplete: {
      return {
        ...adapter.updateOne(
          {
            id: action.payload.postId,
            changes: {
              ...state.entities[action.payload.postId],
              nice_count: state.entities[action.payload.postId].nice_count + 1,
              nice_users: [
                ...state.entities[action.payload.postId].nice_users,
                action.payload.user_id,
              ],
            },
          },
          state
        ),
      };
    }

    case PostsActionTypes.DestroyNiceComplete: {
      return {
        ...adapter.updateOne(
          {
            id: action.payload.postId,
            changes: {
              ...state.entities[action.payload.postId],
              nice_count: state.entities[action.payload.postId].nice_count - 1,
              nice_users: state.entities[
                action.payload.postId
              ].nice_users.filter(user => user !== action.payload.user_id),
            },
          },
          state
        ),
      };
    }

    case PostsActionTypes.PutCurrentIndexNumber: {
      return {
        ...state,
        currentImageIndex: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getSelectedId = (state: State) => state.selectedPostId;
export const getIsDirectLink = (state: State) => state.isDirectLink;
