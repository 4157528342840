import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatter } from './../util/date-formatter';

@Pipe({
  name: 'jst',
})
export class JstPipe implements PipeTransform {
  constructor(private dateFormatter: DateFormatter) {}

  transform(timestamp: string): string {
    return timestamp ? this.dateFormatter.toJstWithDayOfTheWeek(timestamp) : '';
  }
}
