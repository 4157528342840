import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

import { Post } from '../../models/post.model';

export enum PostsActionTypes {
  // 検索
  Search = '[Posts] Search',
  SearchError = '[Posts] Search Error',
  SearchComplete = '[Posts] Search Complete',
  // 検索ページネーション
  SearchNext = '[Posts] Search Next',
  SearchNextComplete = '[Posts] Search Next Complete',
  // 検索結果件数取得完了
  SearchCount = '[Posts] Search Count',
  // Selecterの絞り込み条件変更
  ToggleNonAlbumPostOnly = '[Post] Toggle Non-Album Post Only',
  // 指定したIDの投稿を取得
  GetPost = '[Posts] Get Post',
  GetPostError = '[Posts] Get Post Error',
  // 指定したIDの投稿を取得(Ajax)
  FetchPost = '[Posts] Fetch Post',
  FetchPostComplete = '[Posts] Fetch Post Complete',
  FetchPostError = '[Posts] Fetch Post Error',
  // 投稿を作成
  CreatePost = '[Posts] Create Post',
  CreatePostComplete = '[Posts] Create Post Complete',
  CreatePostError = '[Posts] Create Post Error',
  // 投稿を作成
  CreateMultiplePost = '[Posts] Create Multiple Post',
  CreateMultiplePostComplete = '[Posts] Create Multiple Post Complete',
  CreateMultiplePostError = '[Posts] Create Multiple Post Error',
  // 投稿を編集
  UpdatePost = '[Posts] Update Post',
  UpdatePostComplete = '[Posts] Update Post Complete',
  UpdatePostError = '[Posts] Update Post Error',
  PutCurrentIndexNumber = '[Posts] Put Current Index Number',
  // クリア
  DeletePost = '[Posts] Delete Post',
  DeletePostComplete = '[Posts] Delete Post Complete',
  DeletePostError = '[Posts] Delete Post Error',
  // ストア全体のクリア
  DeleteAllPost = '[Posts] Delete All Post',
  DeleteAllPostComplete = '[Posts] Delete All Post Complete',
  DeleteAllPostError = '[Posts] Delete All Post Error',
  // いいね
  CreateNice = '[Posts] Create Nice',
  CreateNiceComplete = '[Posts] Create Nice Complete',
  CreateNiceError = '[Posts] Create Nice Error',
  // いいね取り消し
  DestroyNice = '[Posts] Destroy Nice',
  DestroyNiceComplete = '[Posts] Destroy Nice Complete',
  DestroyNiceError = '[Posts] Destroy Nice Error',
  // まとめてダウンロード
  ClearAllIds = '[Posts] Clear AllIds',
  AddIdsToAllIds = '[Posts] Add Ids To AllIds',
  UpdateDownloadIds = '[Posts] Update Download Ids',
  // Other
  OtherNiceError = '[Posts] Other Nice Error',

}

export class Search implements Action {
  readonly type = PostsActionTypes.Search;

  constructor(public payload: Params) {}
}

export class SearchNext implements Action {
  readonly type = PostsActionTypes.SearchNext;

  constructor(public payload: Params) {}
}

export class SearchComplete implements Action {
  readonly type = PostsActionTypes.SearchComplete;

  constructor(public payload: Post[]) {}
}

export class SearchNextComplete implements Action {
  readonly type = PostsActionTypes.SearchNextComplete;

  constructor(public payload: Post[]) {}
}

export class SearchError implements Action {
  readonly type = PostsActionTypes.SearchError;

  constructor(public payload: any) {}
}

export class ToggleNonAlbumPostOnly implements Action {
  readonly type = PostsActionTypes.ToggleNonAlbumPostOnly;
}

export class SearchCount implements Action {
  readonly type = PostsActionTypes.SearchCount;

  constructor(public payload: number) {}
}

export class GetPost implements Action {
  readonly type = PostsActionTypes.GetPost;

  constructor(public payload: string) {}
}

export class GetPostError implements Action {
  readonly type = PostsActionTypes.GetPostError;

  constructor(public payload: any) {}
}

export class FetchPost implements Action {
  readonly type = PostsActionTypes.FetchPost;

  constructor(public payload: string) {}
}

export class FetchPostComplete implements Action {
  readonly type = PostsActionTypes.FetchPostComplete;

  constructor(public payload: Post) {}
}

export class FetchPostError implements Action {
  readonly type = PostsActionTypes.FetchPostError;

  constructor(public payload: any) {}
}

export class CreatePost implements Action {
  readonly type = PostsActionTypes.CreatePost;

  constructor(public payload: Post) {}
}

export class CreatePostComplete implements Action {
  readonly type = PostsActionTypes.CreatePostComplete;

  constructor(public payload: Post) {}
}

export class CreatePostError implements Action {
  readonly type = PostsActionTypes.CreatePostError;

  constructor(public payload: any) {}
}

export class CreateMultiplePost implements Action {
  readonly type = PostsActionTypes.CreateMultiplePost;

  constructor(public payload: Post[]) {}
}

export class CreateMultiplePostComplete implements Action {
  readonly type = PostsActionTypes.CreateMultiplePostComplete;

  constructor(public payload: Post[]) {}
}

export class CreateMultiplePostError implements Action {
  readonly type = PostsActionTypes.CreateMultiplePostError;

  constructor(public payload: any) {}
}

export class UpdatePost implements Action {
  readonly type = PostsActionTypes.UpdatePost;

  constructor(public payload: Post) {}
}

export class UpdatePostComplete implements Action {
  readonly type = PostsActionTypes.UpdatePostComplete;

  constructor(public payload: Post) {}
}

export class UpdatePostError implements Action {
  readonly type = PostsActionTypes.UpdatePostError;

  constructor(public payload: any) {}
}

export class PutCurrentIndexNumber implements Action {
  readonly type = PostsActionTypes.PutCurrentIndexNumber;

  constructor(public payload: number) {}
}

export class DeletePost implements Action {
  readonly type = PostsActionTypes.DeletePost;

  constructor(public payload: string) {}
}

export class DeletePostComplete implements Action {
  readonly type = PostsActionTypes.DeletePostComplete;

  constructor(public payload: string) {}
}

export class DeletePostError implements Action {
  readonly type = PostsActionTypes.DeletePostError;

  constructor(public payload: any) {}
}

export class DeleteAllPost implements Action {
  readonly type = PostsActionTypes.DeleteAllPost;
}

export class DeleteAllPostComplete implements Action {
  readonly type = PostsActionTypes.DeleteAllPostComplete;

  constructor(public payload: string) {}
}

export class DeleteAllPostError implements Action {
  readonly type = PostsActionTypes.DeleteAllPostError;

  constructor(public payload: any) {}
}

export class CreateNice implements Action {
  readonly type = PostsActionTypes.CreateNice;

  constructor(public payload: string) {}
}

export class CreateNiceComplete implements Action {
  readonly type = PostsActionTypes.CreateNiceComplete;

  constructor(public payload: any) {}
}

export class CreateNiceError implements Action {
  readonly type = PostsActionTypes.CreateNiceError;

  constructor(public payload: any) {}
}

export class DestroyNice implements Action {
  readonly type = PostsActionTypes.DestroyNice;

  constructor(public payload: string) {}
}

export class DestroyNiceComplete implements Action {
  readonly type = PostsActionTypes.DestroyNiceComplete;

  constructor(public payload: any) {}
}

export class DestroyNiceError implements Action {
  readonly type = PostsActionTypes.DestroyNiceError;

  constructor(public payload: any) {}
}

export class OtherNiceError implements Action {
  readonly type = PostsActionTypes.OtherNiceError;

  constructor(public payload: any) {}
}


export class AddIdsToAllIds implements Action {
  readonly type = PostsActionTypes.AddIdsToAllIds;
}

export class ClearAllIds implements Action {
  readonly type = PostsActionTypes.ClearAllIds;
}

export class UpdateDownloadIds implements Action {
  readonly type = PostsActionTypes.UpdateDownloadIds;

  constructor(public payload: string[]) {}
}

export type PostsActions =
  | Search
  | SearchNext
  | SearchComplete
  | SearchNextComplete
  | SearchError
  | SearchCount
  | ToggleNonAlbumPostOnly
  | GetPost
  | GetPostError
  | FetchPost
  | FetchPostComplete
  | FetchPostError
  | CreatePost
  | CreatePostComplete
  | CreatePostError
  | CreateMultiplePost
  | CreateMultiplePostComplete
  | CreateMultiplePostError
  | UpdatePost
  | UpdatePostComplete
  | UpdatePostError
  | PutCurrentIndexNumber
  | DeletePost
  | DeletePostComplete
  | DeletePostError
  | DeleteAllPost
  | DeleteAllPostComplete
  | DeleteAllPostError
  | CreateNice
  | CreateNiceComplete
  | CreateNiceError
  | DestroyNice
  | DestroyNiceComplete
  | DestroyNiceError
  | AddIdsToAllIds
  | ClearAllIds
  | UpdateDownloadIds;
