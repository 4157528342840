<div id="container">
  <!-- イメージサイズ取得用 -->
  <img *ngIf="imageUrl" id="shadow-image" [src]="imageUrl" (load)="setCanvas()" crossorigin="anonymous" alt="">

  <div id="canvas-wapper">
    <canvas id="canvas"></canvas>
  </div>

  <div class="control" fxLayout="row" fxLayoutAlign="space-between" [style.width.px]="controlWidth">
  <div class="control__inner">
    <button class="icon-button" mat-icon-button matTooltip="戻る" (click)="undo()">
      <mat-icon aria-label="Example icon-button with a heart icon">arrow_back</mat-icon>
    </button>
    <button class="icon-button" mat-icon-button matTooltip="進む" (click)="redo()">
      <mat-icon aria-label="Example icon-button with a heart icon">arrow_forward</mat-icon>
    </button>
  </div>
  <div class="control__inner">
    <button class="color-circle-black" mat-mini-fab  matTooltip="色の変更" (click)="onChangeDrawingColor('#000000')">
      <mat-icon *ngIf="getBruchColor() === '#000000'; else elseBlock">adjust</mat-icon>
      <ng-template #elseBlock><mat-icon>panorama_fisheye</mat-icon></ng-template>
    </button>
    <button class="color-circle-red" mat-mini-fab  matTooltip="色の変更" (click)="onChangeDrawingColor('#f75b4b')">
      <mat-icon *ngIf="getBruchColor() === '#f75b4b'; else elseBlock">adjust</mat-icon>
      <ng-template #elseBlock><mat-icon>panorama_fisheye</mat-icon></ng-template>
    </button>
    <button class="color-circle-green" mat-mini-fab  matTooltip="色の変更" (click)="onChangeDrawingColor('#23bba0')">
      <mat-icon *ngIf="getBruchColor() === '#23bba0'; else elseBlock">adjust</mat-icon>
      <ng-template #elseBlock><mat-icon>panorama_fisheye</mat-icon></ng-template>
    </button>
    <button class="icon-button" mat-icon-button matTooltip="ペンの変更" [matMenuTriggerFor]="penSelect">
      <mat-icon aria-label="Example icon-button with a heart icon">mode_edit</mat-icon>
    </button>
    <mat-menu #penSelect="matMenu">
      <mat-grid-list cols="2" rowHeight="1:1">
        <mat-grid-tile>
          <div class="color-circle-black color-circle-1px" (click)="onChangeDrawingWidth(1)"></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="color-circle-black color-circle-3px" (click)="onChangeDrawingWidth(3)"></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="color-circle-black color-circle-5px" (click)="onChangeDrawingWidth(5)"></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="color-circle-black color-circle-10px" (click)="onChangeDrawingWidth(10)"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-menu>
  </div>
  <div class="control__inner">
    <button mat-raised-button (click)="onCancelEditing()">キャンセル</button>
    <button mat-raised-button (click)="onFinishEditing()">完了</button>
  </div>
</div>
