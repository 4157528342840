import { Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../../models';
import {
  CommentsActions,
  CommentsActionTypes,
} from '../actions/comments.actions';

export interface State extends EntityState<Comment> {}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: (comment: Comment) => comment.comment_id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({});

export function reducer(state = initialState, action: CommentsActions): State {
  switch (action.type) {
    case CommentsActionTypes.LoadCommentsComplete: {
      return {
        ...adapter.setAll(action.payload, state),
      };
    }

    case CommentsActionTypes.AddCommentComplete: {
      return {
        ...adapter.addOne(action.payload, state),
      };
    }

    case CommentsActionTypes.DeleteCommentComplete: {
      return {
        ...adapter.removeOne(action.payload, state),
      };
    }

    case CommentsActionTypes.UpdateCommentComplete: {
      if (action.payload.media) {
        return {
          ...adapter.updateOne(
            { id: action.payload.comment_id, changes: action.payload },
            state
          ),
        };
      } else {
        return {
          ...adapter.updateOne(
            {
              id: action.payload.comment_id,
              changes: {
                ...action.payload,
                media: null,
              },
            },
            state
          ),
        };
      }
    }

    default:
      return state;
  }
}
