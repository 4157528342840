<div class="search-form__wapper">
  <mat-card class="search-form__card">
    <mat-card-content class="search-form__content">
      <form [formGroup]="detailSearchForm">
        <!-- 期間選択 -->
        <div class="form-full-width" fxLayout="row" fxLayoutAlign="space-between">
          <mat-form-field fxFlex="48%">
            <input matInput [matDatepicker]="fromPicker" placeholder="期間(From)" [(ngModel)]="dateFrom" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="48%">
            <input matInput [matDatepicker]="toPicker" placeholder="期間(To)" [(ngModel)]="dateTo" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- 絞り込み条件 -->
        <div class="form-full-width" fxLayout="row" fxLayoutAlign="space-between">
          <mat-form-field class="form-full-width" fxFlex="100%">
            <input type="text" placeholder="イベント" aria-label="Number" matInput formControlName="event" [matAutocomplete]="event">
            <mat-autocomplete #event="matAutocomplete" [displayWith]="getDisplayEvent">
              <mat-option *ngFor="let event of filteredEvents$ | async" [value]="event">
                {{ event.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form-full-width" fxLayout="row" fxLayoutAlign="space-between">
          <mat-form-field class="form-full-width" fxFlex="100%">
            <input type="text" placeholder="部門" aria-label="Number" matInput formControlName="category" [matAutocomplete]="category">
            <mat-autocomplete #category="matAutocomplete" [displayWith]="getDisplayCategory">
              <mat-option *ngFor="let category of filteredCategories$ | async" [value]="category">
                {{ category.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="form-full-width" fxLayout="row" fxLayoutAlign="space-between">

          <mat-form-field fxFlex="48%">
            <mat-select placeholder="グループを選択" [(value)]="selectedGroup" (selectionChange)="onChangeGroupSelect($event)">
              <mat-option *ngFor="let group of shopGroups" [value]="group">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="48%">
            <div *ngFor="let group of shopGroups; let i = index">

              <div *ngIf="selectedGroup.id !== group.id && i === 0 && !isSelectedGroup">
                <mat-form-field style="width: 100%">
                  <mat-select formControlName="shop" placeholder="店舗を選択">
                    <mat-option></mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="selectedGroup.id === group.id">
                <mat-form-field style="width: 100%">
                  <mat-select
                    placeholder="店舗"
                    formControlName="selectedShops"
                    [(value)]="selectedShops[shopGroups.indexOf(group)]"
                    multiple
                  >
                    <div fxLayout="row">
                      <button mat-button fxFlex="50%" (click)="onClickAllSelect(shopGroups.indexOf(group), group)">全店選択</button>
                      <button mat-button fxFlex="50%" (click)="onClickClearAllSelect(shopGroups.indexOf(group))">全店選択解除</button>
                    </div>
                    <mat-option *ngFor="let shop of group.resources" [value]="shop">
                      {{ getShopData(shop)?.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions class="button-center button-mb-8px">
      <button mat-raised-button (click)="onClickPostSearchFilter()">検索条件を保存</button>
      <button mat-raised-button color="accent" class="button__detail-search" (click)="onDetailSearchBtnClicked()">検索</button>
    </mat-card-actions>
  </mat-card>
</div>
