import { Inject, Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  ICognitoUserAttributeData
} from 'amazon-cognito-identity-js';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import * as AWS from 'aws-sdk/global';
import { CognitoIdentityServiceProvider } from 'aws-sdk/clients/all';
import {
  AdminCreateUserRequest,
  AttributeListType
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { Store, select } from '@ngrx/store';

import { CognitoUtil } from './../services/cognito.service';
import { RegistrationUser, NewPasswordUser } from './../models';
import * as fromRoot from './../reducers';
import * as loading from './../core/actions/loading.actions';
import { environment } from './../../environments/environment';

import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';

import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { UserManageService } from './user-manage.service';

/**
 * CognitoUserPoolへのユーザー登録サービス
 *
 * @export
 * @class UserRegistrationService
 */
@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  private cognitoSp;
  CognitoIdentityServiceProvider;

  /**
   * Creates an instance of UserRegistrationService.
   * @param {CognitoUtil} cognitoUtil
   * @memberof UserRegistrationService
   */
  constructor(
    @Inject(CognitoUtil) public cognitoUtil: CognitoUtil,
    @Inject(AuthService) private authService: AuthService,
    private store: Store<fromRoot.State>,
    private userManage: UserManageService
  ) {
    this.cognitoSp = new CognitoIdentityServiceProvider();
  }

  /**
   * ユーザー登録
   * ユーザー仮登録メールの再送信の機能も兼ねる
   *
   * @param {RegistrationUser} user
   * @param {boolean} [resend]
   * @returns {Observable<any>}
   * @memberof UserRegistrationService
   */
  public register(user: RegistrationUser, resend?: boolean): Observable<any> {
    return resend === true
      ? this.userManage.resendEmail(user)
      : this.userManage.registUser(user);
  }

  /**
   * パスワード変更
   *
   * @param {NewPasswordUser} newPasswordUser
   * @returns {Observable<any>}
   * @memberof UserRegistrationService
   */
  public newPassword(newPasswordUser: NewPasswordUser): Observable<any> {
    return this.cognitoUtil.getUserPool().pipe(
      map(
        pool =>
          new CognitoUser({
            Username: newPasswordUser.name,
            Pool: pool
          })
      ),
      switchMap(user =>
        Observable.create((observer: Observer<any>) =>
          user.authenticateUser(
            new AuthenticationDetails({
              Username: newPasswordUser.name,
              Password: newPasswordUser.existingPassword
            }),
            {
              newPasswordRequired: (userAttributes, requiredAttributes) => {
                delete userAttributes.email_verified;
                user.completeNewPasswordChallenge(
                  newPasswordUser.password,
                  requiredAttributes,
                  {
                    onSuccess: result => {
                      observer.next({ error: null, result: userAttributes });
                    },
                    onFailure: error => {
                      observer.error({ error: error, result: null });
                    }
                  }
                );
              },
              onSuccess: result => {
                observer.next({ error: null, result: result });
              },
              onFailure: error => {
                observer.error({ error: error, result: null });
              }
            }
          )
        )
      )
    );
  }
}
