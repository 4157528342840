import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { RevisionService } from './../../services/revision.service';
import { environment } from './../../../../environments/environment';
import { TenantUtil } from '../../../shared/util/tenant-util';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, OnDestroy {
  public appFrontVersion: string;
  public commitFrontVersion: string;
  public appApiVersion: string;
  public commitApiVersion: string;

  // subscriptions
  private subscription: Subscription;

  /**
   * Creates an instance of AboutComponent.
   * @param {RevisionService} revisionService
   * @param {Location} location
   * @memberof AboutComponent
   */
  constructor(
    private revisionService: RevisionService,
    private tenantUtil: TenantUtil,
    private location: Location
  ) {
    this.subscription = new Subscription();
    this.appFrontVersion = environment.appVersion;
    this.commitFrontVersion = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
    this.appApiVersion = this.tenantUtil.apiEndpoint.substring(
      this.tenantUtil.apiEndpoint.length - 2
    );
    this.commitApiVersion = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
  }

  ngOnInit() {
    // ファイルからフロントのGitリビジョンを取得
    const frontVersionSub = this.revisionService.fetchFrontVersion().subscribe(
      result => {
        this.commitFrontVersion = result.revision;
      },
      error => {
        // console.log(error)
      }
    );
    this.subscription.add(frontVersionSub);

    // APIからフロントのGitリビジョンを取得
    const serverVersionSub = this.revisionService.fetchApiVersion().subscribe(
      result => {
        this.commitApiVersion = result.source_version;
      },
      error => {
        // console.log(error)
      }
    );
    this.subscription.add(serverVersionSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * 戻るボタン
   *
   * @memberof AboutComponent
   */
  onClickBack() {
    this.location.back();
  }
}
