import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

import { ApiService } from './api.service';
import {
  Shop,
  ShopGroup,
  Event,
  Category,
  MasterResponse,
  GroupResponse,
  AlbumList,
  AlbumInfo,
  SearchParams,
  SearchFilter,
  Filter,
} from './../models';

@Injectable({
  providedIn: 'root',
})
export class ParametersService {
  private shops: Shop[] = [];
  private events: Event[] = [];
  private categories: Category[] = [];
  private shopGroups: ShopGroup[] = [];

  /**
   * Creates an instance of ParametersService.
   * @param {ApiService} apiService
   * @memberof ParametersService
   */
  constructor(private apiService: ApiService) {}

  /**
   * 店舗一覧を取得
   *
   * @returns {Observable<Shop[]>}
   * @memberof ParametersService
   */
  public getShops(): Observable<Shop[]> {
    return this.apiService.get<MasterResponse>('/master/shop').pipe(
      map(result => {
        this.shops = Object.entries(result.data).map(item => {
          return {
            id: item[0],
            name: item[1].name,
            status: item[1].status === 'ACTIVE' ? true : false,
          };
        });
        return this.shops;
      })
    );
  }

  /**
   * 店舗グループ一覧を取得
   *
   * @returns {Observable<ShopGroup[]>}
   * @memberof ParametersService
   */
  public getShopGroups(): Observable<ShopGroup[]> {
    return this.apiService.get<GroupResponse>('/master/shop/group').pipe(
      map(result => {
        this.shopGroups = Object.entries(result.data).map(item => {
          return {
            id: item[0],
            name: item[1].name,
            resources: item[1].resources,
          };
        });
        return this.shopGroups;
      })
    );
  }

  /**
   * 店舗IDから店舗名を取得
   *
   * @param {string} id
   * @returns {Observable<string>}
   * @memberof ParametersService
   */
  public getShopById(id: string): Observable<string> {
    return this.getShops().pipe(
      map(shops => {
        let result = '';
        shops.forEach(shop => {
          if (id === shop.id) {
            result = shop.name as string;
          }
        });
        return result;
      })
    );
  }

  /**
   * イベント一覧を取得
   *
   * @returns {Observable<Event[]>}
   * @memberof ParametersService
   */
  public getEvents(): Observable<Event[]> {
    return this.apiService.get<MasterResponse>('/master/event').pipe(
      map(result => {
        this.events = Object.entries(result.data).map(item => {
          return {
            id: item[0],
            name: item[1].name,
            status: item[1].status === 'ACTIVE' ? true : false,
          };
        });
        return this.events;
      })
    );
  }

  /**
   * 部門一覧を取得
   *
   * @returns {Observable<Category[]>}
   * @memberof ParametersService
   */
  public getCategories(): Observable<Category[]> {
    return this.apiService.get<MasterResponse>('/master/category').pipe(
      map(result => {
        this.categories = Object.entries(result.data).map(item => {
          return {
            id: item[0],
            name: item[1].name,
            status: item[1].status === 'ACTIVE' ? true : false,
          };
        });
        return this.categories;
      })
    );
  }

  /**
   * 紐付け可能なアルバム
   *
   * @param shop_id: string
   */
  public getAlbumList(shop_id: string): Observable<AlbumInfo[]> {
    const params: HttpParams = new HttpParams().append(
      'shop_id',
      String(shop_id)
    );

    return this.apiService
      .get<AlbumList>('/albums-for-update-post', params)
      .pipe(
        map(albumList => {
          const albums = albumList.albums;
          return albums;
        })
      );
  }

  /**
   * 検索条件取得
   * @param userId
   */
  public getFilter(userId: string): Observable<Filter> {
    return this.apiService
      .get<SearchFilter>(`/posts/filter/${userId}`)
      .pipe(map(result => result.filter));
  }

  /**
   * 検索条件保存
   * @param userId
   * @param params
   */
  public postFilter(userId: string, params: SearchParams): Observable<Filter> {
    return this.apiService
      .post<SearchFilter>(`/posts/filter/${userId}`, params)
      .pipe(map(result => result.filter));
  }
}
