import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/containers/app.component';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer, FullRouterStateSerializer
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { AuthEffects } from './core/effects/auth.effects';
import { MasterEffects } from './core/effects/master.effects';
import { NotificationCountEffects } from './notifications/effects/notification-count.effects';
import { NavigationEffects } from './core/effects/navigation.effects';
import { CustomRouterStateSerializer } from './shared/util/router-state-serializer';
import { GlobalErrorHandlerService } from './services';

@NgModule({ bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        AppRoutingModule.forRoot(),
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 50, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        , connectInZone: true}),
        Angulartics2Module.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        EffectsModule.forRoot([
            AuthEffects,
            MasterEffects,
            NotificationCountEffects,
            NavigationEffects
        ]),
        !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : []], providers: [
        { provide: LOCALE_ID, useValue: 'ja-JP' },
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        // エラーオブジェクトが発生したらAPIに投げる。productionのみ適応
        environment.production
            ? { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
            : [],
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
