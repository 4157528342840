import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { TenantUtil } from '../../shared/util/tenant-util';

@Injectable({
  providedIn: 'root',
})
export class RevisionService {
  /**
   * Creates an instance of RevisionService.
   * @param {HttpClient} http
   * @param {TenantUtil} tenantUtil
   * @memberof RevisionService
   */
  constructor(private http: HttpClient, private tenantUtil: TenantUtil) {}

  /**
   * フロントのバージョン情報法を格納したjsonを取得
   *
   * @returns {Observable<any>}
   * @memberof RevisionService
   */
  public fetchFrontVersion(): Observable<any> {
    return this.http.get('./../../assets/git_sha.json');
  }

  /**
   * バージョン取得APIからAPIのバージョン情報を取得
   * 認証の必要が無いため、個別にAPIを呼び出している
   *
   * @returns {Observable<any>}
   * @memberof RevisionService
   */
  public fetchApiVersion(): Observable<any> {
    return this.http.get(`${this.tenantUtil.apiEndpoint}/version`);
  }

  public setFrontVersion(obj): void {
    localStorage.setItem(
      'revision',
      JSON.stringify(
        Object.assign({}, JSON.parse(localStorage.getItem('revision')), obj)
      )
    );
  }

  public getFrontVersion() {
    return JSON.parse(localStorage.getItem('revision'));
  }

  public getFrontVersionFlag(key) {
    return JSON.parse(localStorage.getItem('revision'))[key];
  }
}
