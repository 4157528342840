import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DateFormatter {
  constructor() {
    moment.locale('ja');
  }

  toJst(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .format('YYYY/MM/DD');
  }

  toJstWithDayOfTheWeek(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .format('YYYY/MM/DD (ddd)');
  }

  toJstFull(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .format('YYYY/MM/DD (ddd) HH:mm');
  }

  toJstTime(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .format('HH:mm');
  }

  toTheDayBeforeJst(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .subtract(1, 'days')
      .format('YYYY/MM/DD');
  }

  getJstDayOfTheWeek(date: string): string {
    return moment(date)
      .tz('Asia/Tokyo')
      .format('ddd');
  }
}
