import { Component, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Store, select } from '@ngrx/store';

import * as fromRoot from './../../reducers';
import * as auth from './../actions/auth.actions';
import { environment } from './../../../environments/environment';

declare var dataLayer: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  // for loading indicator
  loading$: Observable<boolean>;

  private subscription: Subscription;

  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private store: Store<fromRoot.State>
  ) {
    // angulartics2GoogleAnalytics.startTracking();
    this.subscription = new Subscription();

    const routerSub = router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          const title: string = this.getTitle(
            router.routerState,
            router.routerState.root
          ).join('-');
          titleService.setTitle(title);
        }
      },
      error => {
        // console.log(error)
      }
    );
    this.subscription.add(routerSub);

    this.loading$ = this.store.pipe(delay(100), select(fromRoot.getLoading));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  BeforeUnloadEvent() {
    sessionStorage.removeItem(environment.local_searchQueryParam);
  }

  /**
   * タイトル情報をパース
   *
   * @private
   * @param {any} state
   * @param {any} parent
   * @returns {string[]}
   * @memberof AppComponent
   */
  private getTitle(state, parent): string[] {
    const data: string[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
