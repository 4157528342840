import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { take ,  tap ,  catchError } from 'rxjs/operators';

import { UserLoginService } from './../../services';

/**
 * ログイン済みかどうかを判定してログインしていなければ/loginにリダイレクト
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 * @implements {CanActivateChild}
 */
@Injectable()
export class AuthGuard  {
  constructor(private userService: UserLoginService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthed => {
        if (!isAuthed) {
          this.router.navigate(['/login']);
        }
      }),
      catchError(error => {
        // セッションの有効期限切れはErrorがtrowされる
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthed => {
        if (!isAuthed) {
          this.router.navigate(['/login']);
        }
      }),
      catchError(error => {
        // セッションの有効期限切れはErrorがtrowされる
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}
