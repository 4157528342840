import { Action } from '@ngrx/store';
import { SearchActions, SearchActionTypes } from '../actions/search.actions';

export interface State {
  filter: {
    category_id: any | null;
    date_from: string | null;
    date_to: string | null;
    event_id: any | null;
    filter_id: number;
    shops: string[];
    tenant_id: string;
    update_at: string | null;
    user_id: string;
    word: string | null;
  };
}

export const initialState: State = {
  filter: {
    category_id: null,
    date_from: null,
    date_to: null,
    event_id: null,
    filter_id: null,
    shops: [],
    tenant_id: null,
    update_at: null,
    user_id: null,
    word: null,
  },
};

export function reducer(state = initialState, action: SearchActions): State {
  switch (action.type) {
    case SearchActionTypes.SetSearchFilter:
      return {
        ...state,
        filter: action.payload,
      };

    case SearchActionTypes.DeleteSearchFilter:
      return initialState;

    default:
      return state;
  }
}

export const getSearchFilter = (state: State) => state.filter;
