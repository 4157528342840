import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription, zip } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromRoot from './../../../reducers';
import * as navigation from './../../actions/navigation.actions';
import * as search from '../../actions/search.actions';
import { ParametersService } from './../../../services';
import {
  User,
  Shop,
  ShopGroup,
  Event,
  Category,
  SearchParams,
  Filter,
} from './../../../models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  shops$: Observable<Shop[]>;
  events$: Observable<Event[]>;
  categories$: Observable<Category[]>;
  filteredEvents$: Observable<Event[]>;
  filteredCategories$: Observable<Category[]>;
  filter$: Observable<Filter>;

  public user: User;
  public userId: string;
  public shops: Shop[] = [];
  public shopGroups: ShopGroup[] = [];
  public selectedGroup: any = [];
  public selectedShops: any = [];
  public events: Event[] = [];
  public event: any;
  public eventId: any;
  public categories: Category[] = [];
  public categoriesId: any;
  public dateFrom: Date | string;
  public dateTo: Date | string;
  private isFirstSearchBoxOpen = true;
  public isSelectedGroup: boolean;

  private subscription: Subscription;
  detailSearchForm: UntypedFormGroup;

  @Output()
  public clickOutside = new EventEmitter();
  constructor(
    private fb: UntypedFormBuilder,
    private parametersService: ParametersService,
    private store: Store<fromRoot.State>,
    private elementRef: ElementRef,
    private snackBar: MatSnackBar
  ) {
    this.subscription = new Subscription();
    this.isSelectedGroup = false;
    this.user$ = this.store.pipe(select(fromRoot.getUser));
    this.shops$ = this.store.pipe(select(fromRoot.getShops));
    this.events$ = this.store.pipe(select(fromRoot.getEvents));
    this.categories$ = this.store.pipe(select(fromRoot.getCategories));
    this.filter$ = this.store.pipe(select(fromRoot.GetSearchFilter));
    this.detailSearchForm = this.fb.group({
      date_from: new UntypedFormControl('', []),
      date_to: new UntypedFormControl('', []),
      event: new UntypedFormControl('', []),
      category: new UntypedFormControl('', []),
      shop: new UntypedFormControl({ value: '', disabled: true }, []),
      shops: new UntypedFormControl('', []),
      word: new UntypedFormControl('', []),
      selectedShops: new UntypedFormControl([]),
    });
  }


  ngOnInit() {
    const filterSub = zip(
      this.shops$,
      this.events$,
      this.categories$,
      this.parametersService.getShopGroups(),
      this.filter$,
      this.user$
    )
      .pipe(take(1))
      .subscribe(res => {
        // 全ての店舗
        this.shops = res[0].filter(shop => {
          return shop.status;
        });
        const shopIds = this.shops.map(shop => {
          if (shop.id) {
            return shop.id;
          }
        });
        // 全ての店舗IDをデフォルト設定
        this.detailSearchForm.controls.selectedShops.setValue(shopIds);

        // イベント
        this.events = res[1].filter(item => {
          return item.status;
        });
        this.filteredEvents$ = this.detailSearchForm.controls.event.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, this.events))
        );

        // 部門
        this.categories = res[2].filter(item => {
          return item.status;
        });
        this.filteredCategories$ = this.detailSearchForm.controls.category.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, this.categories))
        );

        // 店舗グループ
        this.shopGroups = res[3].map(group => {
          group.resources = this.checkAvailable(group.resources);
          return group;
        });
        // 全件選択用の要素を生成
        const selectAll = {
          id: '',
          name: '全店舗を選択する',
          resources: [...shopIds], // 全ての店舗IDが入る
        };
        this.shopGroups.unshift(selectAll);

        // 保存した店舗一覧を表示するためのオブジェクト
        const filterShops = {
          id: 'filterId',
          name: '保存した検索条件',
          resources: [],
        };

        this.userId = res[5].sub;

        // 検索条件が保存済みかどうか
        if (res[4].user_id != null) {
          this.dateFrom = res[4].date_from;
          this.dateTo = res[4].date_to;
          this.detailSearchForm.controls.event.setValue(res[4].event_id);
          this.detailSearchForm.controls.category.setValue(res[4].category_id);

          // 検索条件で保存された店舗専用のグループを生成
          if (res[4].shops.length > 0) {
            this.isSelectedGroup = true;
            filterShops['resources'] = [...res[4].shops];
            this.shopGroups.unshift(filterShops);
            // 検索条件に保存したグループを設定
            this.selectedGroup = this.shopGroups[0];
          }
          this.selectedShops = [...res[4].shops];
        } else {
          const userSub = this.parametersService
            .getFilter(this.userId)
            .subscribe(result => {
              this.dateFrom = result.date_from;
              this.dateTo = result.date_to;

              // イベント
              const eventObject =
                res[1].filter(event => {
                  return event.id === result.event_id;
                })[0] || null;
              this.detailSearchForm.controls.event.setValue(eventObject);

              // 部門
              const categoryObject =
                res[2].filter(category => {
                  return category.id === result.category_id;
                })[0] || null;
              this.detailSearchForm.controls.category.setValue(categoryObject);

              // 検索条件で保存された店舗専用のグループを生成
              if (result.shops.length > 0) {
                this.isSelectedGroup = true;
                filterShops['resources'] = [...result.shops];
                this.shopGroups.unshift(filterShops);
                // 検索条件に保存したグループを設定
                this.selectedGroup = this.shopGroups[0];
              }
              this.selectedShops = [...result.shops];

              this.store.dispatch(
                new search.SetSearchFilter(
                  Object.assign(
                    {},
                    result,
                    { event_id: eventObject },
                    { category_id: categoryObject }
                  )
                )
              );
            });
          this.subscription.add(userSub);
        }
      });
    this.subscription.add(filterSub);
  }

  ngOnDestroy() {
    this.isFirstSearchBoxOpen = true;
    this.subscription.unsubscribe();
  }

  /**
   * 店舗idの配列から表示するもののみ抽出
   */
  private checkAvailable(resources: string[]) {
    return resources.filter(id => {
      return this.shops
        .map(shop => {
          return shop.id;
        })
        .includes(id);
    });
  }

  /**
   * グループ選択状態を変更する
   *
   * @param {*} event
   * @memberof
   */
  onChangeGroupSelect(event: any) {
    // クリックされた要素がshopGroups配列の何番目の要素かを判定
    let groupIndex = null;
    this.shopGroups.forEach((shopGroup, index) => {
      if (shopGroup.id === event.value.id) {
        groupIndex = index;
      }
    });

    const group = event.value;
    this.selectedShops = [];

    this.isSelectedGroup = true;
    this.onClickAllSelect(groupIndex, group);
  }

  /**
   * 全店舗選択解除
   * @param groupIndex
   */
  onClickClearAllSelect(groupIndex: number) {
    this.selectedShops[groupIndex] = [];
    this.selectedShops = [];
  }

  /**
   * グループに含まれる店舗を全選択
   *
   * @param {number} groupIndex
   * @param {*} group
   * @memberof
   */
  onClickAllSelect(groupIndex: number, group: any) {
    this.selectedShops[groupIndex] = group.resources;
  }

  /**
   * idから店舗の情報を取得
   */
  public getShopData(id: string): Shop {
    return this.shops.filter(item => {
      return item.id === id;
    })[0];
  }

  private _filter(
    value: string,
    list: Category[] | Shop[] | Event[]
  ): Category[] | Shop[] | Event[] {
    const filteredList = list.filter(ele => ele.name.includes(value));
    return value ? filteredList : list;
  }

  /**
   * 検索する
   */
  onDetailSearchBtnClicked() {
    const params: SearchParams = {
      date_from: '',
      date_to: '',
      event_id: '',
      category_id: '',
      shops: [],
    };

    const formValue = this.detailSearchForm.value;
    params.event_id = formValue.event ? formValue.event.id : '';
    params.category_id = formValue.category ? formValue.category.id : '';

    // 保存した検索条件を使用するかどうかの判定
    if (this.isSelectedGroup) {
      // グループ選択時、selectedShopsに生成されるempty要素を削除
      params.shops = this.selectedShops.filter(selectedShop => selectedShop);

      // グループ選択時、params.shopsが多次元配列になるため、shopIdのみをparams.shopsに代入
      // 検索保存条件時はstring[]なので除外
      if (params.shops[0] && typeof params.shops[0] !== 'string') {
        params.shops = [...params.shops[0]];
      }
    }
    // 対象店舗を指定していない場合、[]で来るためnullに変更
    // shops: []の状態でElasticSeachに投げると、有効なクエリパラメータとして認識してしまう為。
    if (params.shops.length === 0) {
      params.shops = null;
    }

    if (this.dateFrom) {
      this.dateFrom = new Date(this.dateFrom);
      params.date_from = this.dateFrom.toISOString().replace('.000', '');
    }
    if (this.dateTo) {
      this.dateTo = new Date(this.dateTo);
      this.dateTo.setDate(this.dateTo.getDate() + 1);
      params.date_to = this.dateTo.toISOString().replace('.000', '');
    }

    this.clickOutside.emit(event);
    this.store.dispatch(
      new navigation.Go({
        path: ['/search'],
        query: params,
      })
    );
  }

  /**
   * 検索条件を保存する
   */
  onClickPostSearchFilter() {
    const params: SearchParams = {
      date_from: '',
      date_to: '',
      event_id: '',
      category_id: '',
      shops: [],
    };
    const formValue = this.detailSearchForm.value;
    params.event_id = formValue.event ? formValue.event.id : '';
    params.category_id = formValue.category ? formValue.category.id : '';

    if (this.isSelectedGroup) {
      // this.selectedShopsからemptyな要素を削除する
      params.shops = this.selectedShops.filter(selectedShop => selectedShop);

      // グループセレクト変更時、多重配列になっているので下記の処理を実行
      if (params.shops[0] && typeof params.shops[0] !== 'string') {
        params.shops = [...params.shops[0]];
      }
    }

    if (this.dateFrom) {
      this.dateFrom = new Date(this.dateFrom);
      params.date_from = this.dateFrom.toISOString().replace('.000', '');
    }
    if (this.dateTo) {
      this.dateTo = new Date(this.dateTo);
      this.dateTo.setDate(this.dateTo.getDate());
      params.date_to = this.dateTo.toISOString().replace('.000', '');
    }

    this.parametersService.postFilter(this.userId, params).subscribe(
      result => {
        this.store.dispatch(
          new search.SetSearchFilter(
            Object.assign(
              {},
              result,
              { event_id: formValue.event },
              { category_id: formValue.category }
            )
          )
        );
        this.snackBar.open('検索条件を保存しました。', '', {
          duration: 3000,
        });
      },
      error => {
        this.snackBar.open('検索条件の保存に失敗しました。', '', {
          duration: 3000,
        });
      }
    );
  }

  // 詳細ボックス以外をクリックした際に詳細ボックスを閉じる
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    // angular-materialのカレンダー(.cdk-overlay-container)とオプション(mat-option-text)のクリックは
    // 親にイベントを伝えないようにする
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const overlay = document.querySelector('.cdk-overlay-container');
    const clickedInOverlay: boolean = overlay
      ? overlay.contains(targetElement)
      : false;
    // mat-optionはクリック時にDOMが消えてしまうので、querySelectorで指定しない
    const clickedInMatOption: boolean = targetElement.classList.contains(
      'mat-mdc-option'
    )
      ? true
      : false;
    // 年・月・日をクリック時にDOMが消えてしまうので、querySelectorで指定しない
    const matCalendarBodyCell: boolean = targetElement.classList.contains(
      'mat-calendar-body-cell-content'
    )
      ? true
      : false;
    // マテリアルUI要素クリック時を除外
    if (clickedInOverlay || clickedInMatOption || matCalendarBodyCell) {
      return;
    }
    if (!clickedInside && !this.isFirstSearchBoxOpen) {
      this.clickOutside.emit(event);
    }
    // 初回、検索窓クリック時を除外するためのフラグ処理
    if (this.isFirstSearchBoxOpen) {
      this.isFirstSearchBoxOpen = false;
    }
  }

  // angular-materialのautocompleteで、表示に使うメソッド群
  // 参考: https://stackoverflow.com/questions/43325526/angular-material2-autocomplete-binding-with-complex-object-list-doesnt-behave-a
  getDisplayEvent(event?) {
    return event ? event.name : '';
  }

  getDisplayCategory(category) {
    return category ? category.name : '';
  }

  getDisplayShop(shop) {
    return shop ? shop.name : '';
  }
}
