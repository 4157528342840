import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorNotiferService } from './error-notifer.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any) {
    const errorNotiferService = this.injector.get(ErrorNotiferService);
    errorNotiferService.postFrontError(error);
  }
}
