import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationStart,
} from '@angular/router';
import { Title } from '@angular/platform-browser';import { DOCUMENT } from "@angular/common";
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription, interval } from 'rxjs';
import { take, map, switchMap, tap, throttle } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { CognitoUtil } from './../../../services';
import { RevisionService } from './../../../core/services/revision.service';
import { panelOpenTrigger } from './../../../shared/animations/animations';
import * as fromRoot from './../../../reducers';
import * as layout from './../../actions/layout.actions';
import * as auth from './../../actions/auth.actions';
import * as master from './../../../core/actions/master.actions';
import * as navigation from './../../actions/navigation.actions';
import * as notificationCount from './../../../notifications/actions/notification-count.actions';
import { environment } from './../../../../environments/environment';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrls: ['./secure-layout.component.scss'],
  animations: [panelOpenTrigger],
})
export class SecureLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true })
  public sidenav: MatSidenav;
  @ViewChild(ToolbarComponent, { static: true })
  public toolbarComponent: ToolbarComponent;

  showSidenav$: Observable<boolean>;
  sidenavMode$: Observable<string>;
  showSearchForm$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  unreadCount$: Observable<number>;

  public pageTitle: string;
  public sidenavPos: number;
  private startPos = 0;
  public toolBarPos = 0;
  private toolbarOffset = 200;
  // 大体モバイル画面の高さ
  private scrollTopOffset = 800;
  public showSearchForm: boolean;
  public showMobileSearchForm = false;
  public showScrollTopButton = false;
  public params: any;

  // subscriptions
  private subscription: Subscription;

  /**
   * Creates an instance of SecureLayoutComponent.
   * @param {Document} document
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {Title} titleService
   * @param {ParametersService} parametersService
   * @memberof SecureLayoutComponent
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private cognitoUtil: CognitoUtil,
    private store: Store<fromRoot.State>,
    private revisionService: RevisionService
  ) {
    this.subscription = new Subscription();
    // routerの遷移のたびに未読通知数を再取得する
    const routerSub = this.router.events
      .pipe(
        tap(() => (this.pageTitle = this.titleService.getTitle())),
        map(event => {
          if (event instanceof NavigationStart) {
            return event;
          }
        }),
        // router.eventsが大量に流れるのを間引く
        throttle(() => interval(100)),
        switchMap(event => {
          return this.revisionService.fetchFrontVersion();
        })
      )
      .subscribe(
        data => {
          const frontVerion = this.revisionService.getFrontVersion();
          if (frontVerion && data) {
            if (frontVerion.revision !== data.revision) {
              this.revisionService.setFrontVersion(data);
              const revisionChangingFlag = this.revisionService.getFrontVersionFlag(
                'revisionChangingFlag'
              );
              this.revisionService.setFrontVersion({
                revisionChangingFlag: true,
              });
              if (!revisionChangingFlag) {
                this.revisionService.setFrontVersion({
                  revisionChangingFlag: true,
                });
                location.reload();
              }
            } else {
              // リビジョンが一致したのでLocal Storageのリビジョン更新フラグをfalseに
              this.revisionService.setFrontVersion({
                revisionChangingFlag: false,
              });
            }
          } else {
            this.revisionService.setFrontVersion(data);
          }
        },
        error => console.log(error)
      );

    this.subscription.add(routerSub);

    // ページ更新が走ったタイミングでjwtをstoreに保存
    const cognitoSub = this.cognitoUtil
      .getSession()
      .pipe(take(1))
      .subscribe((session: any) => {
        this.store.dispatch(
          new auth.SetUserSession({
            jwtToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
          })
        );
      });
    this.subscription.add(cognitoSub);

    this.store.dispatch(new auth.GetUser());
    this.store.dispatch(new auth.GetSignedUrl());
    this.store.dispatch(new notificationCount.LoadNotificationCount());
    this.store.dispatch(new master.LoadShops());
    this.store.dispatch(new master.LoadEvents());
    this.store.dispatch(new master.LoadCategories());

    this.showSidenav$ = this.store.pipe(select(fromRoot.getShowSidenav));
    this.sidenavMode$ = this.store.pipe(select(fromRoot.getSidenavMode));
    this.showSearchForm$ = this.store.pipe(select(fromRoot.getShowSearchForm));
    this.isAdmin$ = this.store.pipe(select(fromRoot.getIsAdmin));
    this.unreadCount$ = this.store.pipe(
      select(fromRoot.getgetNotificationCount)
    );
  }

  ngOnInit() {
    this.showSearchForm$.pipe().subscribe(isShowSearchForm => {
      this.showSearchForm = isShowSearchForm;
    });

    // 画面幅に応じてStoreのStateをイニシャライズ
    if (this.isOver()) {
      this.store.dispatch(new layout.OverSidenav());
    } else {
      this.store.dispatch(new layout.SideSidenav());
      this.store.dispatch(new layout.OpenSidenav());
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * サイドナビを常に表示する条件
   *
   * @returns {boolean}
   * @memberof SecureLayoutComponent
   */
  private isOver(): boolean {
    return window.matchMedia(`(max-width: 960px)`).matches ? true : false;
  }

  /**
   * Homeであるか
   * (投稿ボタンを出し分けるために必要)
   *
   * @returns {boolean}
   * @memberof SecureLayoutComponent
   */
  isHome(): boolean {
    const url = this.router.url;
    return url === '/' || !url.indexOf('/search') ? true : false;
  }

  /**
   * アルバムであるか
   * (投稿ボタンを出し分けるために必要)
   *
   * @returns {boolean}
   * @memberof SecureLayoutComponent
   */
  isAlbum(): boolean {
    const url = this.router.url.split('?')[0];
    return url === '/album' ? true : false;
  }

  /**
   * サイドナビゲーションのメニュー項目をクリックした際に閉じる
   *
   * @param {string} itemName
   * @memberof SecureLayoutComponent
   */
  onClickMenuItem(itemName: string) {
    if (this.isOver()) {
      this.store.dispatch(new layout.CloseSidenav());
    }
    this.store.dispatch(new navigation.Go({ path: [`${itemName}`] }));
  }

  onMobileSearch(): void {
    this.showMobileSearchForm = false;
  }

  /**
   * サイドナビゲーションのマニュアル項目をクリックした際にマニュアルをブラウザで開く
   *
   * @param {string} userType
   * @memberof SecureLayoutComponent
   */
  onClickManualDownload(userType: string) {
    switch (userType) {
      case 'user':
        window.open(
          'https://s3-ap-northeast-1.amazonaws.com/postfor/public/Postfor_manual.pdf',
          '_blank'
        );
        break;
      case 'admin':
        window.open(
          'https://s3-ap-northeast-1.amazonaws.com/postfor/public/Postfor_manual_admin.pdf',
          '_blank'
        );
        break;
    }
  }

  /**
   * ページトップへ戻る
   *
   * @memberof SecureLayoutComponent
   */
  onClickScrollToTop() {
    const optionsObject = {
      top: 0,
      left: 0,
      behavior: 'smooth',
    };
    window.scroll(optionsObject as any);
  }

  /**
   * モバイル検索フォームの状態変更
   *
   * @param {boolean} isOpen
   * @memberof SecureLayoutComponent
   */
  toggleMobileSearchForm(isOpen: boolean) {
    this.showMobileSearchForm = isOpen;
  }

  // 画面のリサイズを検知
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isOver()) {
      this.store.dispatch(new layout.OverSidenav());
    } else {
      this.store.dispatch(new layout.SideSidenav());
    }
  }

  // スクロール量を検知
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const currentPos = this.document.documentElement.scrollTop;

    // scrollToTopボタンを制御
    this.showScrollTopButton =
      currentPos >= this.scrollTopOffset ? true : false;
  }

  /**
   * 検索詳細ボックス以外をクリックした際に検索詳細ボックスを閉じる
   * @param {Event} event
   */
  onClickOutside(event: Event) {
    if (event) {
      this.store.dispatch(new layout.CloseSearchForm());
    }
  }
}
