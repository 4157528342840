import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';

import { ImageUrlService } from './../../../services';
import { DeleteModalComponent } from './../../../shared/components/delete-modal/delete-modal.component';
import { Media } from './../../../models';
import { TenantUtil } from '../../util/tenant-util';
import objectFitImages from 'object-fit-images';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as fromPosts from '../../../posts/reducers';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements OnInit, OnDestroy {
  @Input()
  thumbImages: Media[];
  @Input()
  type: string;
  @Input()
  enableDelete: boolean;
  @Output()
  changeCurrentImageIndex = new EventEmitter<any>();
  @Output()
  deleteImage = new EventEmitter<any>();

  currentImageIndex$: Observable<number>;

  private currentImageIndex: number;
  private mediaEndpoint: string;
  private mediaSignature: string;

  // subscriptions
  private subscription: Subscription;

  /**
   * Creates an instance of ImageViewComponent.
   * @param {MatDialog} dialog
   * @param {ImageUrlService} imageUrlService
   * @memberof ImageViewComponent
   */
  constructor(
    private dialog: MatDialog,
    private imageUrlService: ImageUrlService,
    private tenantUtil: TenantUtil,
    private store: Store<fromRoot.State>
  ) {
    this.mediaEndpoint = this.tenantUtil.mediaEndpoint;
    this.subscription = new Subscription();
    this.currentImageIndex$ = this.store.pipe(
      select(fromPosts.getCurrentImageIndex)
    );
    const currentImageIndexSub = this.currentImageIndex$.subscribe(index => {
      this.currentImageIndex = index || 0;
    });
    this.subscription.add(currentImageIndexSub);
  }

  ngOnInit() {
    const imageUrlServiceSub = this.imageUrlService
      .getSignedUrl()
      .subscribe(url => {
        this.mediaSignature = url;
      });
    this.subscription.add(imageUrlServiceSub);
    // object-fitをEDGE,IE11で有効化するためのPolyfill
    objectFitImages(false, {
      watchMQ: true,
      skipTest: true,
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * 署名付き画像URLを取得
   *
   * @returns {string}
   * @memberof ImageViewComponent
   */
  getThumbImage(): string {
    let currentImage = '';
    if (
      this.thumbImages !== undefined &&
      this.thumbImages !== null &&
      this.mediaSignature
    ) {
      const thumbImage = this.thumbImages[this.currentImageIndex];
      if (thumbImage !== undefined) {
        currentImage = `${this.mediaEndpoint}M/${thumbImage.id}.${
          thumbImage.ext
        }?${this.mediaSignature}`;
      } else {
        currentImage =
          this.type === 'ref'
            ? './../../../assets/images/no-reference-image_4x3.png'
            : '';
      }
    } else {
      currentImage =
        this.type === 'ref'
          ? './../../../assets/images/no-reference-image_4x3.png'
          : '';
    }
    return currentImage;
  }

  /**
   * 戻るボタンが押された
   *
   * @memberof ImageViewComponent
   */
  onClickPrevious() {
    const imageUrlServiceSub = this.imageUrlService
      .getSignedUrl()
      .subscribe(url => {
        this.mediaSignature = url;
      });
    this.subscription.add(imageUrlServiceSub);

    this.currentImageIndex--;
    this.changeCurrentImageIndex.emit(this.currentImageIndex);
  }

  /**
   * 次へボタンが押された
   *
   * @memberof ImageViewComponent
   */
  onClickNext() {
    const imageUrlServiceSub = this.imageUrlService
      .getSignedUrl()
      .subscribe(url => {
        this.mediaSignature = url;
      });
    this.subscription.add(imageUrlServiceSub);

    this.currentImageIndex++;
    this.changeCurrentImageIndex.emit(this.currentImageIndex);
  }

  /**
   * 画像が2枚以上あり次へボタンが有効か
   *
   * @returns {boolean}
   * @memberof ImageViewComponent
   */
  isEnabledNext(): boolean {
    if (this.thumbImages !== undefined && this.thumbImages !== null) {
      return this.thumbImages.length - 1 > this.currentImageIndex
        ? true
        : false;
    } else {
      return false;
    }
  }

  /**
   * 画像が2枚以上あり戻るボタンが有効か
   *
   * @returns {boolean}
   * @memberof ImageViewComponent
   */
  isEnabledPrevious(): boolean {
    if (this.thumbImages !== undefined && this.thumbImages !== null) {
      return this.currentImageIndex !== 0 ? true : false;
    } else {
      return false;
    }
  }

  /**
   * 削除できる画像かどうか
   *
   * @returns {boolean}
   * @memberof ImageViewComponent
   */
  isEnabledClear(): boolean {
    // アルバム参考画像は削除できない
    if (
      this.thumbImages !== undefined &&
      this.thumbImages !== null &&
      this.enableDelete !== false
    ) {
      return this.thumbImages.length > 1 ? true : false;
    } else {
      return false;
    }
  }

  /**
   * 削除ボタンが押された
   *
   * @memberof ImageViewComponent
   */
  onClickClear() {
    this.openMediaDeleteDialog();
  }

  //////// Private //////////

  /**
   * 選択中の画像を削除
   *
   * @private
   * @memberof ImageViewComponent
   */
  private clearSelectedImg() {
    this.thumbImages.splice(this.currentImageIndex, 1);
    this.deleteImage.emit(this.thumbImages);
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    }
  }

  /**
   * 画像削除確認ダイアログを開く
   *
   * @private
   * @memberof ImageViewComponent
   */
  private openMediaDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        messages: 'この写真を削除しますか?',
        true: 'はい',
        false: 'いいえ',
      },
    });
    const dialogRefSub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // 削除実処理
        this.clearSelectedImg();
      }
    });
    this.subscription.add(dialogRefSub);
  }
}
