import { Post } from './post.model';
import { Comment } from './comment.model';
import { AlbumInfo } from './album.model';
import { User } from './user.model';

export namespace reasonTypeConst {
  export const COMMENT = 'comment';
  export const REPLY = 'reply';
  export const ALBUM_CREATE = 'album_create';
  export const ALBUM_POST_CREATE = 'album_post_create';
  export const ALBUM_STATUS_UPDATE = 'album_status_update';
  export const ALBUM_SHOP_STATUS_UPDATE = 'album_shop_status_update';
  export const NICE_CREATE = 'nice_create';
}

// resonが取る値
type reasonType =
  | 'comment'
  | 'reply'
  | 'album_create'
  | 'album_post_create'
  | 'album_status_update'
  | 'album_shop_status_update'
  | 'nice_create';

export class Notification {
  success: boolean;
  total: number;
  notifications: Notifications[];
}

export class Notifications {
  notification_id: number;
  post?: Post; // or album
  album?: AlbumInfo; // or post
  read: number;
  reason: reasonType;
  updated_at: string;
  comment?: Comment; // reasonがcommentまたはreplyの場合のみ
  action_user: User;
}

export interface NotificationQueryParams {
  per_page?: number;
  page?: number;
}
