import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
  GetUser,
  GetUserComplete,
  GetUserError,
  GetSignedUrl,
  GetSignedUrlComplete,
  GetSignedUrlError,
} from '../actions/auth.actions';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { UserParametersService } from '../../services/user-parameters.service';
import { ImageUrlService } from '../../services/image-url.service';
import { User } from '../../models';

@Injectable()
export class AuthEffects {
  
  getUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GetUser>(AuthActionTypes.GetUser),
    switchMap(() =>
      this.userParametersService
        .getParameters()
        .pipe(
          map((user: User) => new GetUserComplete(user)),
          catchError(error => of(new GetUserError(error)))
        )
    )
  ));

  
  getSignedUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GetSignedUrl>(AuthActionTypes.GetSignedUrl),
    switchMap(() =>
      this.imageUrlService
        .getSignedUrl()
        .pipe(
          map((signature: string) => new GetSignedUrlComplete(signature)),
          catchError(error => of(new GetSignedUrlError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private userParametersService: UserParametersService,
    private imageUrlService: ImageUrlService
  ) {}
}
