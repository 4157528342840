<!-- clear(image) button -->
<div class="clear-button">
  <a (click)="clearSelectedImg()"><mat-icon>clear</mat-icon></a>
</div>

<div class="photo__container" [ngClass.lt-sm]="{'photo__container--mobile': true}">
  <div class="photo__image" [style.background-image]="currentPreviewImage"></div>

  <button mat-fab color="primary" class="chevron chevron-left" [ngClass.lt-sm]="{'chevron--mobile': true}" (click)="onClickPrevious()" *ngIf="isEnabledPrevious()">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button mat-fab color="primary" class="chevron chevron-right" [ngClass.lt-sm]="{'chevron--mobile': true}" (click)="onClickNext()" *ngIf="isEnabledNext()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
