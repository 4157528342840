export class User {
  sub: string;
  name: string;
  shop_id: string;
  shop?: string;
  email: string;
  groups: string[];
  authorityStr?: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export class UserlistUser extends User {
  status: string;
  userCreateDate: Date;
  userLastModifiedDate: Date;

  constructor(init?: Partial<UserlistUser>) {
    super(init);
    Object.assign(this, init);
  }
}

export interface UserSession {
  jwtToken: string;
  refreshToken: string;
}

export class ManageListUser {
  username: string;
  name: string;
  user_status: string;
  created_at: Date;
  updated_at: Date;
  enabled: boolean;
  shop_id: string;
  role: string;
  email: string;
  sub: string;
}
