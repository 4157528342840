import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public appName = 'app';
  constructor(private location: Location) {
    this.appName = environment.appName;
  }

  // 戻るボタン
  onClickBack() {
    this.location.back();
  }
}
