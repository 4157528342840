import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import * as RouterActions from './../actions/navigation.actions';
import * as fromRoot from './../../reducers';
import * as notificationCount from './../../notifications/actions/notification-count.actions';

import { NotificationsService } from './../services/notifications.service';

@Injectable()
export class NavigationEffects {
  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.GO),
    map((action: RouterActions.Go) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      if (
        path[0] === '/login' ||
        path[0] === '/logout' ||
        path[0] === '/about'
      ) {
        return;
      }
      if (extras !== undefined) {
        if (extras.fragment) {
          return;
        }
      }
      this.store.dispatch(new notificationCount.LoadNotificationCount());
    }),
    tap(({ path, query: queryParams, extras }) =>
      this.router.navigate(path, { queryParams, ...extras })
    )
  ), { dispatch: false });

  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.BACK),
    tap(() => this.location.back())
  ), { dispatch: false });

  
  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this.location.forward())
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store<fromRoot.State>
  ) {}
}
